import { TableBody } from '@mui/material';
import { cloneDeep, isUndefined } from 'lodash-es';
import { useState } from 'react';

import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableCellDefaultText, TableCellLink, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { REPORTS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getEffectivenessVariantColor, numberAsEffectivenessString } from 'Models/OperationalControls';
import { ControlEffectivenessFilterOptionTypes, InherentRiskFilterOptionTypes, ReportListingTableFilterOptions, ResidualRiskFilterOptionTypes, RiskWorkflowArchive, getRiskRatingVariantColor, numberAsRiskRatingString } from 'Models/TPRM';
import { Filter, Filters } from 'Models/Types/GlobalType';

import { ReportListingFilters, ReportListingFiltersProps } from './ReportListingFilters/ReportListingFilters';
import styles from './ReportListingTab.module.css';

export interface ReportListingTabProps {
    controlAssessmentReports: RiskWorkflowArchive[];
}

interface ReportListingTabState {
    currentSort: string;
    currentSortDirection: SortDirection;
}

const useFilteredReports = (reports: RiskWorkflowArchive[]) => {
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<Filters>({});

    const filterChanged = (updatedFilter: Filter | Filter[]): void => {
        const currentFilterList = cloneDeep(selectedFilterOptions);

        if (updatedFilter instanceof Array) {
            updatedFilter.forEach((filter) => {
                if (filter.value.length > 0) {
                    currentFilterList[filter.key] = filter;
                } else {
                    delete currentFilterList[filter.key];
                }
            });
        } else {
            if (updatedFilter.value.length > 0) {
                currentFilterList[updatedFilter.key] = updatedFilter;
            } else {
                delete currentFilterList[updatedFilter.key];
            }
        }

        setSelectedFilterOptions(currentFilterList);
    };

    let reportCopy = [...reports];

    if (Object.keys(selectedFilterOptions).length > 0) {
        for (const filter of Object.values(selectedFilterOptions)) {
            reportCopy = reportCopy.filter((report) => {
                if (filter.key === ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS) {
                    return filter.value.includes(report.service.assessment_control_effectiveness!);
                } else if (filter.key === ReportListingTableFilterOptions.INHERENT_RISK_SCORE) {
                    return filter.value.includes(report.service.inherent_risk_score);
                }
                return filter.value.includes(report.service.assessment_residual_risk_score!);
            });
        }
    }

    return [reportCopy, filterChanged] as const;
};

export const ReportListingTab = (props: ReportListingTabProps): JSX.Element => {
    const [reportTableState, setReportTableState] = useState<ReportListingTabState>({ currentSort: ReportListingTableFilterOptions.DATE_CREATED, currentSortDirection: SortDirection.DESC });
    const [reports, filterChanged] = useFilteredReports(props.controlAssessmentReports);

    const reportListingFiltersProps: ReportListingFiltersProps = {
        filterOptions: [
            {
                label: 'Inherent Risk Rating',
                options: InherentRiskFilterOptionTypes,
            },
            {
                label: 'Residual Risk',
                options: ResidualRiskFilterOptionTypes,
            },
            {
                label: 'Control Effectiveness',
                options: ControlEffectivenessFilterOptionTypes,
            },
        ],
        selectedFilterOptions: filterChanged,
    };

    const headerValues: HeaderData[] = [
        { dataKey: ReportListingTableFilterOptions.REPORT_TITLE, label: 'TITLE', disableSort: true },
        { dataKey: ReportListingTableFilterOptions.DATE_CREATED, label: 'DATE CREATED' },
        { dataKey: ReportListingTableFilterOptions.INHERENT_RISK_SCORE, label: 'INHERENT RISK' },
        { dataKey: ReportListingTableFilterOptions.RESIDUAL_RISK_SCORE, label: 'RESIDUAL RISK' },
        { dataKey: ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS, label: 'CONTROL EFFECTIVENESS' },
    ];

    const sortReports = (reportPassedIn: RiskWorkflowArchive[]): RiskWorkflowArchive[] => {
        let sortResult = 0;

        const sortedReports = cloneDeep(reportPassedIn);

        return sortedReports.sort((reportItemA, reportItemB) => {
            switch (reportTableState.currentSort) {
                case ReportListingTableFilterOptions.DATE_CREATED:
                    sortResult = reportItemA.metadata.timestamp > reportItemB.metadata.timestamp ? 1 : -1;
                    break;
                case ReportListingTableFilterOptions.CONTROL_EFFECTIVENESS:
                    sortResult = reportItemA.service.assessment_control_effectiveness! > reportItemB.service.assessment_control_effectiveness! ? 1 : -1;
                    break;
                case ReportListingTableFilterOptions.INHERENT_RISK_SCORE:
                    sortResult = reportItemA.service.inherent_risk_score! > reportItemB.service.inherent_risk_score! ? 1 : -1;
                    break;
                case ReportListingTableFilterOptions.RESIDUAL_RISK_SCORE:
                    sortResult = reportItemA.service.assessment_residual_risk_score! > reportItemB.service.assessment_residual_risk_score! ? 1 : -1;
                    break;
            }

            return reportTableState.currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    const setCurrentSort = (newSort: string, newSortDirection: SortDirection): void => {
        setReportTableState({ currentSort: newSort as ReportListingTableFilterOptions, currentSortDirection: newSortDirection });
    };

    const tableRow = (controlAssessmentReport: RiskWorkflowArchive, index: number) => {
        return (
            <TableRow key={index}>
                <TableCellLink link={`/${TPRM}/${THIRD_PARTIES}/${controlAssessmentReport.service.vendor_id}/${SERVICES}/${controlAssessmentReport.service.id}/${REPORTS}/${controlAssessmentReport.metadata.timestamp}`}>Service Assessment Summary</TableCellLink>
                <TableCellDefaultText>
                    <Text noStyles={true}>{iso8601ToUsDateShort(controlAssessmentReport.metadata.timestamp)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <div className={styles.riskRating}>
                        <CircleIndicator variant={getRiskRatingVariantColor(controlAssessmentReport.service.inherent_risk_score)} />
                        <div className={styles.tableText}>
                            <Text noStyles={true}>{numberAsRiskRatingString(controlAssessmentReport.service.inherent_risk_score)}</Text>
                        </div>
                    </div>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    {controlAssessmentReport.service.assessment_residual_risk_score && (
                        <div className={styles.riskRating}>
                            <CircleIndicator variant={getRiskRatingVariantColor(controlAssessmentReport.service.assessment_residual_risk_score)} />
                            <div className={styles.tableText}>
                                <Text noStyles={true}>{numberAsRiskRatingString(controlAssessmentReport.service.assessment_residual_risk_score)}</Text>
                            </div>
                        </div>
                    )}
                </TableCellDefaultText>
                <TableCellDefaultText>
                    {!isUndefined(controlAssessmentReport.service.assessment_control_effectiveness) && controlAssessmentReport.service.assessment_control_effectiveness > 0 && (
                        <div className={styles.riskRating}>
                            <CircleIndicator variant={getEffectivenessVariantColor(controlAssessmentReport.service.assessment_control_effectiveness)} />
                            <div className={styles.tableText}>
                                <Text noStyles={true}>{numberAsEffectivenessString(controlAssessmentReport.service.assessment_control_effectiveness)}</Text>
                            </div>
                        </div>
                    )}
                </TableCellDefaultText>
            </TableRow>
        );
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: setCurrentSort,
        currentSort: reportTableState.currentSort,
        currentSortDirection: reportTableState.currentSortDirection,
    };

    return (
        <>
            <div className={styles.filter}>
                <ReportListingFilters {...reportListingFiltersProps} />
            </div>
            <Table>
                <SortableTableHeader {...sortableTableProps} />
                <TableBody>{sortReports(reports).map(tableRow)}</TableBody>
            </Table>
        </>
    );
};
