/*
	Settings.tsx -- Page that contains tabs for user settings, company settings, and configuration settings for external integrations.
*/

import { faGlobe, faShieldHalved, faSitemap, faSlidersH, faTableCells } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import { ControlsApi } from 'Api/Controls/ControlsApi';
import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { RiskRegisterApi } from 'Api/RiskRegister/RiskRegisterApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { TagsApi } from 'Api/Tags/TagsApi';
import { Button, Link } from 'Components/Buttons/Buttons';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { Role } from 'Components/Context/RBACContext';
import { Page } from 'Components/Page/Page';
import { Text } from 'Components/Text/Text';
import { CONFIGURATION, DDQ, EXTERNAL_INTEGRATIONS, OPERATIONAL_CONTROLS, TOGGLE, TPRM, USERS } from 'Config/Paths';

import { ManageFolderTypesModal } from './ManageFolderTypesModal/ManageFolderTypesModal';
import { ManageRiskCategoriesModal } from './ManageRiskCategoriesModal/ManageRiskCategoriesModal';
import { ManageRiskStrategiesModal } from './ManageRiskStrategiesModal/ManageRiskStrategiesModal';
import { ManageTagsModal } from './ManageTagsModal/ManageTagsModal';
import { ServiceAssessmentScheduleModal } from './ServiceAssessmentScheduleModal/ServiceAssessmentScheduleModal';
import styles from './Settings.module.css';
import { TargetMaturityModal } from './TargetMaturityModal/TargetMaturityModal';

enum PageElements {
    None,
    ControlAssociationToggle,
    DueDiligenceConfiguration,
    ManageFolderTypes,
    ManageRiskCategories,
    ManageRiskStrategies,
    ManageTagsModal,
    ServiceAssessmentSchedule,
    TargetMaturity,
}

export interface SettingsProps {
    controlsApi: ControlsApi;
    dashboardApi: DashboardApi;
    riskRegisterApi: RiskRegisterApi;
    tagsApi: TagsApi;
    tprmApi: TPRMApi;
}

export const Settings = (props: SettingsProps): JSX.Element => {
    const [displayedPageElement, setDisplayedPageElement] = useState<PageElements>(PageElements.None);

    return (
        <RBACComponent roles={[Role.ADMIN]}>
            {displayedPageElement === PageElements.ManageFolderTypes && <ManageFolderTypesModal hideModal={() => setDisplayedPageElement(PageElements.None)} tprmApi={props.tprmApi} />}
            {displayedPageElement === PageElements.ManageRiskCategories && <ManageRiskCategoriesModal hideModal={() => setDisplayedPageElement(PageElements.None)} riskRegisterApi={props.riskRegisterApi} />}
            {displayedPageElement === PageElements.ManageRiskStrategies && <ManageRiskStrategiesModal hideModal={() => setDisplayedPageElement(PageElements.None)} riskRegisterApi={props.riskRegisterApi} />}
            {displayedPageElement === PageElements.ManageTagsModal && <ManageTagsModal hideModal={() => setDisplayedPageElement(PageElements.None)} tagsApi={props.tagsApi} />}
            {displayedPageElement === PageElements.ServiceAssessmentSchedule && <ServiceAssessmentScheduleModal hideModal={() => setDisplayedPageElement(PageElements.None)} tprmApi={props.tprmApi} />}
            {displayedPageElement === PageElements.TargetMaturity && <TargetMaturityModal hideModal={() => setDisplayedPageElement(PageElements.None)} dashboardApi={props.dashboardApi} />}
            <Page
                headerTitle="Settings"
                body={[
                    {
                        content: (
                            <>
                                <div className={styles.icon}>
                                    <FontAwesomeIcon icon={faGlobe} />
                                    <Text variant="Header4" noStyles>
                                        Global Settings
                                    </Text>
                                </div>
                                <div className={styles.settingRowContainer}>
                                    <div className={styles.settingDescription}>
                                        <Text variant="Header3">External Integrations</Text>
                                        <Text variant="Text3" noStyles>
                                            Configure integrations with external applications and services to automate data collection.
                                        </Text>
                                    </div>
                                    <Link variant="secondaryButton" to={`/${CONFIGURATION}/${EXTERNAL_INTEGRATIONS}`} fontAwesomeImage={faSlidersH}>
                                        EDIT
                                    </Link>
                                </div>
                                <div className={styles.settingRowContainer}>
                                    <div className={styles.settingDescription}>
                                        <Text variant="Header3">Manage Users</Text>
                                        <Text variant="Text3" noStyles>
                                            Create and modify user accounts. Assign roles to users to control access to SummIT Security features and data.
                                        </Text>
                                    </div>
                                    <Link variant="secondaryButton" to={`/${CONFIGURATION}/${USERS}`} fontAwesomeImage={faSlidersH}>
                                        EDIT
                                    </Link>
                                </div>
                                <div className={styles.settingRowContainer}>
                                    <div className={styles.settingDescription}>
                                        <Text variant="Header3">Tags</Text>
                                        <Text variant="Text3" noStyles>
                                            Manage tags to label and organize actions and risks.
                                        </Text>
                                    </div>
                                    <Button variant="secondary" onClick={() => setDisplayedPageElement(PageElements.ManageTagsModal)} fontAwesomeImage={faSlidersH}>
                                        EDIT
                                    </Button>
                                </div>
                                <hr className={styles.hr} />
                                <div className={styles.icon}>
                                    <FontAwesomeIcon icon={faShieldHalved} />
                                    <Text variant="Header4" noStyles>
                                        Control Automation
                                    </Text>
                                </div>
                                <div className={styles.settingRowContainer}>
                                    <div className={styles.settingDescription}>
                                        <Text variant="Header3">Toggle Controls</Text>
                                        <Text noStyles>Enable and disable controls, groups of controls, or entire control frameworks. Disabled items will not appear within SummIT Security, will remove related configurations such as limits, notifications, and recurring assessments, and will unmap things like metrics, governance documents, compliance requirements, and open issues/exceptions.</Text>
                                    </div>
                                    <Link variant="secondaryButton" to={`/${CONFIGURATION}/${OPERATIONAL_CONTROLS}/${TOGGLE}`} fontAwesomeImage={faSlidersH}>
                                        EDIT
                                    </Link>
                                </div>
                                <div className={styles.settingRowContainer}>
                                    <div className={styles.settingDescription}>
                                        <Text variant="Header3">Set Target Maturity</Text>
                                        <Text noStyles>Select the FFIEC CAT maturity level your organization is currently targeting.</Text>
                                    </div>
                                    <Button variant="secondary" onClick={() => setDisplayedPageElement(PageElements.TargetMaturity)} fontAwesomeImage={faSlidersH}>
                                        EDIT
                                    </Button>
                                </div>
                                <hr className={styles.hr} />
                                <div className={styles.icon}>
                                    <FontAwesomeIcon icon={faSitemap} />
                                    <Text variant="Header4" noStyles>
                                        Third-Party Risk Management
                                    </Text>
                                </div>
                                <div className={styles.settingRowContainer}>
                                    <div className={styles.settingDescription}>
                                        <Text variant="Header3">Third-Party Questionnaire</Text>
                                        <Text noStyles>Configure the questions used to derive third-party questionnaires for third-party services based on their inherent risk ratings.</Text>
                                    </div>
                                    <Link variant="secondaryButton" to={`/${CONFIGURATION}/${TPRM}/${DDQ}`} fontAwesomeImage={faSlidersH}>
                                        EDIT
                                    </Link>
                                </div>
                                <div className={styles.settingRowContainer}>
                                    <div className={styles.settingDescription}>
                                        <Text variant="Header3">Service Assessment Schedule</Text>
                                        <Text noStyles>Configure assessment schedule recommendations for third-party services, based on their inherent risk ratings.</Text>
                                    </div>
                                    <Button variant="secondary" onClick={() => setDisplayedPageElement(PageElements.ServiceAssessmentSchedule)} fontAwesomeImage={faSlidersH}>
                                        EDIT
                                    </Button>
                                </div>
                                <div className={styles.settingRowContainer}>
                                    <div className={styles.settingDescription}>
                                        <Text variant="Header3">Folder Types</Text>
                                        <Text noStyles>Manage folder types for Third-Party Risk Management document uploads.</Text>
                                    </div>
                                    <Button variant="secondary" onClick={() => setDisplayedPageElement(PageElements.ManageFolderTypes)} fontAwesomeImage={faSlidersH}>
                                        EDIT
                                    </Button>
                                </div>
                                <hr className={styles.hr} />
                                <div className={styles.icon}>
                                    <FontAwesomeIcon icon={faTableCells} />
                                    <Text variant="Header4" noStyles>
                                        Risk Management
                                    </Text>
                                </div>
                                <div className={styles.settingRowContainer}>
                                    <div className={styles.settingDescription}>
                                        <Text variant="Header3">Risk Categories</Text>
                                        <Text noStyles>Define categories that risks are grouped by, such as Financial, Legal, Operational, Reputational, and Technology.</Text>
                                    </div>
                                    <Button variant="secondary" onClick={() => setDisplayedPageElement(PageElements.ManageRiskCategories)} fontAwesomeImage={faSlidersH}>
                                        EDIT
                                    </Button>
                                </div>
                                <div className={styles.settingRowContainer}>
                                    <div className={styles.settingDescription}>
                                        <Text variant="Header3">Risk Strategies</Text>
                                        <Text noStyles>Define strategies for handling risks, such as Accept, Mitigate, and Transfer.</Text>
                                    </div>
                                    <Button variant="secondary" onClick={() => setDisplayedPageElement(PageElements.ManageRiskStrategies)} fontAwesomeImage={faSlidersH}>
                                        EDIT
                                    </Button>
                                </div>
                            </>
                        ),
                    },
                ]}
            />
        </RBACComponent>
    );
};
