import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';

import styles from './ClearIRQModal.module.css';

export interface ClearIRQModalProps {
    hideModal: () => void;
    tprmApi: TPRMApi;
    irqCleared: () => void;
    thirdPartyId: string;
    serviceID: string;
}

export const ClearIRQModal = (props: ClearIRQModalProps): JSX.Element => {
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
    const [failureMessage, setFailureMessage] = useState<string | undefined>(undefined);
    const [isClearingIRQ, setIsClearingIRQ] = useState<boolean>(false);

    const deleteIRQ = async (): Promise<void> => {
        try {
            setIsClearingIRQ(true);
            setSuccessMessage(undefined);
            setFailureMessage(undefined);
            await props.tprmApi.deleteIRQ(props.thirdPartyId, props.serviceID);
            setSuccessMessage('Inherent risk questionnaire cleared.');
            props.irqCleared();
        } catch (error) {
            setFailureMessage(error.message);
        } finally {
            setIsClearingIRQ(false);
        }
    };

    const hideModal = (): void => {
        if (!isClearingIRQ) {
            props.hideModal();
        }
    };

    return (
        <Modal show onHide={hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="modalFromBody">
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <ModalHeader text="Clear" />
                <div className={styles.deleteText}>
                    <Text>Are you sure you want to clear the inherent risk questionnaire?</Text>
                    <Text>This will make the service inactive, and any progress on the third-party questionnaire will be lost.</Text>
                </div>

                <div className={'modalFormButtonContainer'}>
                    <Button variant="secondary" onClick={hideModal} fontAwesomeImage={faTimes} disabled={isClearingIRQ}>
                        CLOSE
                    </Button>
                    <Button variant="danger" onClick={() => deleteIRQ()} fontAwesomeImage={faTrash} loadingText={'Clearing...'} isLoading={isClearingIRQ} disabled={successMessage ? true : false}>
                        CLEAR
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};
