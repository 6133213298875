import { faBars, faThLarge } from '@fortawesome/free-solid-svg-icons';

import { IconButton } from 'Components/Buttons/IconButton';
import { UserFilter } from 'Components/Filters/UserFilter/UserFilter';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { ThirdPartyListingSortProperty } from 'Models/TPRM';
import { Filter, GroupOptionType, OptionType } from 'Models/Types/GlobalType';

import styles from './ThirdPartyListingBodyToolbar.module.css';
import { ThirdPartyListingView } from '../ThirdPartyListing';

export interface ThirdPartyListingBodyToolbarProps {
    switchListingView: (activeThirdPartyListingView: ThirdPartyListingView) => void;
    activeThirdPartyListingView: ThirdPartyListingView;
    defaultSelectedOptions?: GroupOptionType[];
    filterThirdParties: (selectedFilterOptions: Filter | Filter[]) => void;
    sortCardsBy: (sortProperty: string, sortDirection: SortDirection) => void;
    currentlySortedBy: string;
}

const ThirdPartyListingSortOptions: OptionType[] = [
    {
        label: 'Name',
        value: ThirdPartyListingSortProperty.NAME,
    },
    {
        label: 'Date Created',
        value: ThirdPartyListingSortProperty.CREATED_TIME,
    },
    {
        label: 'Third-Party Manager',
        value: ThirdPartyListingSortProperty.THIRD_PARTY_MANAGER_USER_ID,
    },
];

export const ThirdPartyListingBodyToolbar = (props: ThirdPartyListingBodyToolbarProps): JSX.Element => {
    const handleSortSelectionChange = (value: ChangeEventType): void => {
        const property = value as ThirdPartyListingSortProperty;
        if (property) {
            props.sortCardsBy(property, SortDirection.ASC);
        }
    };

    return (
        <div className={styles.thirdPartyListingFilter}>
            <div className={styles.filter}>
                {props.activeThirdPartyListingView === ThirdPartyListingView.GRID && (
                    <div className={styles.sortSelect}>
                        <FormFieldSelect options={ThirdPartyListingSortOptions} handleChange={handleSortSelectionChange} formFieldId="sortThirdPartiesBy" formFieldLabel="Sort" selectedOption={props.currentlySortedBy} />
                    </div>
                )}
                <div className={styles.filterSelect}>
                    <UserFilter filterLabel="Filter by Third-Party Manager" onUsersSelected={props.filterThirdParties} userFilterId="vendor_manager_user_id" formFieldId="thirdPartyManagerFilter" />
                </div>
            </div>
            <div className={styles.tableAndGridButtonPanel}>
                <IconButton aria-label="Switch to Third-Party Grid View" onClick={() => props.switchListingView(ThirdPartyListingView.GRID)} fontAwesomeImage={faThLarge} disabled={props.activeThirdPartyListingView === ThirdPartyListingView.GRID} />
                <IconButton aria-label="Switch to Third-Party List View" onClick={() => props.switchListingView(ThirdPartyListingView.LIST)} fontAwesomeImage={faBars} disabled={props.activeThirdPartyListingView === ThirdPartyListingView.LIST} />
            </div>
        </div>
    );
};
