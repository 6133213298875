/**
 * READ BEFORE MODIFYING THIS FILE.
 * --------------------------------
 * This file is intended to provide consistency to client-side routes (URLs) and server-side API endpoints (URLs).
 * This file is not intended to be a dumping ground for myriad constants with slight variations between them. It loses its benefits if that happens.
 * Soon our API will be made available to customers/partners to develop integrations, so it is important that we make the URLs consistent and intuitive.
 * --------------------------------
 * 1a. Use existing descriptive words before creating new constants.
 *      Right: Use an existing constant, like "configuration".
 *      Wrong: Create synonyms of existing constants, like "setup", "build", "construct", etc.
 * 1b. Use existing feature terminology before creating new constants.
 *      Right: Use an existing constant, like "ddq".
 *      Wrong: Create a synonym of an existing constant, like "due_diligence".
 *      Right: Build paths using existing constants, like "/ddq/configuration/".
 *      Wrong: Create paths using new constants like "/ddq_configuration/".
 * 2. Try to make client-side routes match server-side API endpoints (may not always be possible). Dynamic client-side routes are created via helper functions defined in src/Helpers/URLBuilder.
 *      Right: If the client-side route is "/tprm/third_parties/{third_party_id}/services/{service_id}", make the corresponding API endpoint the same.
 *      Wrong: If the client-side route is "/tprm/third_parties/{third_party_id}/services/{service_id}", make the corresponding API endpoint /tprm/{third_party_id}/{service_id}.
 * 3. Always use plurals. It may read strangely in some circumstances, but it makes things simpler to have that consistency rather than doubling the number of constants and having to decide when to use singular vs. plural.
 *      Right: /tprm/third_parties
 *      Wrong: /tprm/third_party
 * 4. Don't create a new constant when the value already exists.
 *      Right: "export const ASSESSMENTS = 'assessments';"
 *      Wrong:
 *          "export const ASSESSMENTS = 'assessments';"
 *          "export const SERVICE_ASSESSMENTS = 'assessments';"
 * 5. If a more specific constant exists, make it more general rather than creating a new constant.
 *      Right: If "control_groups" exists, change it to "groups" and reuse that.
 *      Wrong: If "control_groups" exists, define a new constant "ddq_groups" to use.
 * 6. When creating new URLs, follow the natural hierarchy of the feature they apply to. For example, anything related to Operational Controls would start with "/operational_controls" and anything related to Third-Party Risk Management would start with "/tprm".
 *      Right: To view a list of existing Third Parties: GET /tprm/third_parties
 *      Right: To create a new Third Party: POST /tprm/third_parties
 *      Right: To view an existing Third Parties: GET /tprm/third_parties/{third_party_id}
 *      Right: To modify an existing Third Party: POST /tprm/third_parties/{third_party_id}
 */

export const ACTIONS = 'actions';
export const ACTIVE = 'active';
export const ACTIVATE = 'activate'; // TODO: Replace with "toggle", or vice versa.
export const ARTIFICIAL_INTELLIGENCE = 'ai';
export const ALERTS = 'alerts';
export const ASSESSMENTS = 'assessments';
export const AWS_CONFIG = 'aws_config';
export const CATEGORY = 'category';
export const CLIENTS = 'clients';
export const COMMON = 'common';
export const COMPLIANCE_REQUIREMENTS = 'compliance_requirements';
export const CONFIGURATION = 'configuration';
export const CONTROL = 'control';
export const CONTROLS = 'controls';
export const CONTROL_WEIGHTS = 'control_weights';
export const CURRENT = 'current';
export const CUSTOM = 'custom';
export const CREATE = 'create';
export const DASHBOARDS = 'dashboards';
export const DDQ = 'ddq';
export const DEACTIVATE = 'deactivate'; // TODO: Replace with "toggle", or vice versa.
export const DOWNLOAD = 'download';
export const DETAILS = 'details';
export const DOCUMENTS = 'documents';
export const EFFECTIVENESS = 'effectiveness';
export const ERRORS = 'errors';
export const EVIDENCE = 'evidence';
export const EXCEPTIONS = 'exceptions';
export const EXPORTS = 'exports';
export const EXTERNAL_INTEGRATIONS = 'external_integrations';
export const FFIEC_CAT = 'ffiec_cat';
export const FILE_PROCESSING = 'file_processing';
export const FOLDERS = 'folders';
export const FRAMEWORKS = 'frameworks';
export const GOVERNANCE = 'governance';
export const GROUPS = 'groups';
export const HISTORY = 'history';
export const INACTIVE = 'inactive';
export const INTEGRATIONS = 'integrations';
export const IRQ = 'irq';
export const ISSUES = 'issues';
export const ISSUES_EXCEPTIONS = 'issues_exceptions'; // This is the name of a specific feature. It may make sense to rename this if/when "Action Planning/Tracking" is released as the current thought is that "Actions" will be similar to "Issues" and "Exceptions". "issues_exceptions_actions" is kind of wordy, so we probably want to come up with a word that holistically describes the three.
export const KEEP_ALIVE = 'keep_alive';
export const LIMITS = 'limits';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const MAPPING = 'mapping';
export const METRICS = 'metrics';
export const NOTIFICATIONS = 'notifications';
export const OPERATIONAL_CONTROLS = 'operational_controls';
export const POTENTIAL_PARENTS = 'potential_parents';
export const PUBLIC = 'public';
export const QUESTIONS = 'questions';
export const RISKS = 'risks';
export const RISK_REVIEW = 'risk_review';
export const RISK_REGISTER = 'risk_register';
export const RISK_SCORES = 'risk_scores';
export const REGISTER = 'register';
export const REPORTS = 'reports';
export const REQUIREMENTS = 'requirements';
export const SCHEDULE = 'schedule';
export const SERVICES = 'services';
export const STATUS = 'status';
export const STRATEGY = 'strategy';
export const SUMMARY = 'summary';
export const TAGS = 'tags';
export const TARGET_MATURITY = 'target_maturity';
export const TARGET_EFFECTIVENESS = 'target_effectiveness';
export const TEMPLATES = 'templates';
export const TOGGLE = 'toggle'; // TODO: Replace with "activate" and "deactivate", or vice versa? Could also possibly eliminate this and make the API endpoint something like /operational_controls/configuration?
export const TPRM = 'tprm';
export const UPLOAD = 'upload';
export const USERS = 'users';
export const THIRD_PARTY_ID = 'third_party_id'; // Query parameter when passing third party from third-party listing to service listing
export const THIRD_PARTY_MONITORING = 'third_party_monitoring'; // Refers to a specific Metric ("Third-Party Monitoring") that is used to retrieve real-time risk scoring information for a particular Third Party. Could possibly be renamed to be more generic, like "scores" or "risk".
export const THIRD_PARTIES = 'third_parties';
export const VERSIONS = 'versions';
