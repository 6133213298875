import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemIcon, IconButton as MaterialIconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

import { Text } from 'Components/Text/Text';

import styles from './Button.module.css';

const DOT_COLOR_DEFAULT = 'black';
interface OverflowItem {
    text: string;
    onClickAction: () => void;
    icon?: IconProp;
}

export interface OverflowMenuProps {
    overflowItems: OverflowItem[];
    overflowDotsColor?: 'black' | 'darkGray';
    accessibilityTitle: string; //Passed to the FontAwesomeIcon which enables accessibility on the overflow button.
    disabled?: boolean;
}

export const OverflowMenu: React.FC<OverflowMenuProps> = ({ overflowItems, overflowDotsColor = DOT_COLOR_DEFAULT, accessibilityTitle, disabled = false }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const menuItemHandleClick = (overflowItem: OverflowItem): void => {
        setAnchorEl(null);
        overflowItem.onClickAction();
    };

    const overflowItem = (overflowItem: OverflowItem, index: number): JSX.Element => {
        return (
            <MenuItem onClick={() => menuItemHandleClick(overflowItem)} key={index}>
                {overflowItem.icon && (
                    <ListItemIcon sx={{ color: 'var(--hps-dark-blue)', paddingRight: '5px' }}>
                        <FontAwesomeIcon icon={overflowItem.icon} size="2x" />
                    </ListItemIcon>
                )}
                <Text noStyles={true}>{overflowItem.text}</Text>
            </MenuItem>
        );
    };

    return (
        <>
            <MaterialIconButton disabled={disabled} onClick={handleClick}>
                <FontAwesomeIcon title={accessibilityTitle} icon={faEllipsisV} className={overflowDotsColor === DOT_COLOR_DEFAULT ? styles.overflowDots : styles.overflowDotsDarkGray} />
            </MaterialIconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                disableScrollLock
                open={anchorEl !== null}
                onClose={handleClose}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {overflowItems.map(overflowItem)}
            </Menu>
        </>
    );
};
