/*
	ControlGroupGridView.tsx -- Component for viewing control groups in a grid format.
*/

import { ControlCard } from 'Components/ControlCard/ControlCard';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { ControlGroupResponse } from 'Models/OperationalControls';

import styles from './ControlGroupGridView.module.css';

export interface ControlGroupGridViewProps {
    controlGroupResponse: ControlGroupResponse[];
}

export const ControlGroupGridView = (props: ControlGroupGridViewProps): JSX.Element => {
    return <div className={styles.gridView}>{props.controlGroupResponse && props.controlGroupResponse.map((controlGroupResponse: ControlGroupResponse) => <ControlCard key={controlGroupResponse.control_group_id} effectiveness={controlGroupResponse.control_group_effectiveness} description={controlGroupResponse.control_group_description ? controlGroupResponse.control_group_description : ''} link={getFrameworkGroupControlURL(`${controlGroupResponse.control_framework}#${controlGroupResponse.control_group_id}`)} linkText="VIEW CONTROL GROUP" title={controlGroupResponse.is_custom === true ? controlGroupResponse.control_group_name : `${controlGroupResponse.control_group_id}. ${controlGroupResponse.control_group_name}`} />)}</div>;
};
