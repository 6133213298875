import { CircleIndicator } from 'Components/Indicator/CircleIndicator';
import { Text } from 'Components/Text/Text';
import { NumberAsRiskScore, getRiskRatingVariantColor, numberAsRiskRatingString } from 'Models/TPRM';

import styles from './TableRiskStatusBar.module.css';

export interface TableRiskStatusBarProps {
    risk: number;
}

export const TableRiskStatusBar = (props: TableRiskStatusBarProps) => {
    return (
        <div className={styles.riskContainer}>
            <CircleIndicator variant={getRiskRatingVariantColor(NumberAsRiskScore(props.risk))} />
            <div className={styles.riskTextContainer}>
                <Text noStyles={true}>{numberAsRiskRatingString(props.risk)}</Text>
            </div>
        </div>
    );
};
