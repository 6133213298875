import { CompletedAssessment, OperationalControl, UncompletedAssessment } from './OperationalControls';
import { ServiceAssessmentState } from './TPRM';
import { OptionType } from './Types/GlobalType';

export interface TprmDashboardCompletedRiskAssessment {
    vendor_id: string;
    vendor_name: string;
    service_id: string;
    service_name: string;
    residual_risk_score: number;
    inherent_risk_score: number;
    control_effectiveness: number;
    completed_time: string;
    completed_on_time: boolean;
}

export interface TprmDashboardMonthlyRiskPortfolioSummary {
    month: MonthTimeframe;
    statistics: {
        average_inherent_risk: number;
        average_control_effectiveness: number;
        average_residual_risk: number;
    };
}

export interface TprmDashboardService {
    id: string;
    name: string;
    third_party_id: string;
    third_party_name: string;
    inherent_risk_score: number;
    irq_completed_time: string;
    assessment_state: ServiceAssessmentState;
    assessment_control_effectiveness?: number;
    assessment_residual_risk_score?: number;
    assessment_due_date?: string;
    assessment_completed_time?: string;
}

export interface TprmDashboardServiceExtended extends TprmDashboardService {
    is_overdue: boolean;
    is_upcoming: boolean;
}

export interface TprmDashboardThirdPartiesAndServicesInfo {
    third_party_count: number;
    services: TprmDashboardService[];
}

export enum TprmDashboardRiskType {
    RESIDUAL = 'Residual Risk',
    CONTROL_EFFECTIVENESS = 'Control Effectiveness',
    INHERENT = 'Inherent Risk',
}

export const isValidDashboardRiskType = (value: unknown): value is TprmDashboardRiskType => Object.values(TprmDashboardRiskType).includes(value as TprmDashboardRiskType);

export const DashboardRiskOptions: OptionType[] = [
    {
        value: 'Residual Risk',
        label: 'Residual Risk',
    },
    {
        value: 'Control Effectiveness',
        label: 'Control Effectiveness',
    },
    {
        value: 'Inherent Risk',
        label: 'Inherent Risk',
    },
];

export enum DateOptionSelectOptions {
    PREVIOUS_QUARTER = 'previous_quarter',
    PREVIOUS_SIX_MONTHS = 'previous_six_months',
    PREVIOUS_YEAR = 'previous_year',
    NEXT_QUARTER = 'next_quarter',
    NEXT_SIX_MONTHS = 'next_six_months',
    NEXT_YEAR = 'next_year',
    CUSTOM = 'custom',
}

export const isValidDateOption = (value: unknown): value is DateOptionSelectOptions => Object.values(DateOptionSelectOptions).includes(value as DateOptionSelectOptions);

export const DefaultDateRangeSelectOptions: OptionType[] = [
    { value: DateOptionSelectOptions.PREVIOUS_QUARTER, label: 'Last 3 Months' },
    { value: DateOptionSelectOptions.PREVIOUS_SIX_MONTHS, label: 'Last 6 Months' },
    { value: DateOptionSelectOptions.PREVIOUS_YEAR, label: 'Last Year' },
];

export const UpcomingDateRangeSelectOptions: OptionType[] = [
    { value: DateOptionSelectOptions.NEXT_QUARTER, label: 'Next 3 Months' },
    { value: DateOptionSelectOptions.NEXT_SIX_MONTHS, label: 'Next 6 Months' },
    { value: DateOptionSelectOptions.NEXT_YEAR, label: 'Next Year' },
];
// OC Dashboards

export interface ControlStrengthResponse {
    controls_effectiveness_average: number;
    controls_by_effectiveness: {
        INACTIVE: OperationalControl[];
        FAIL: OperationalControl[];
        WEAK: OperationalControl[];
        MODERATE: OperationalControl[];
        STRONG: OperationalControl[];
        ROBUST: OperationalControl[];
    };
}

export interface ComplianceSummary {
    regulation: string;
    met_count: number;
    not_met_count: number;
    not_mapped_count: number;
    awaiting_assessments_count: number;
}

export type MonthNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface MonthTimeframe {
    month: MonthNumber;
    year: number;
}

export interface ControlsSnapshot {
    month: MonthTimeframe;
    assessments: CompletedAssessment[];
    average_effectiveness: number;
}

export interface ComplianceSummarySnapshot {
    month: MonthTimeframe;
    timestamp: string;
    summary: {
        regulation: string;
        met_count: number;
        not_met_count: number;
        not_mapped_count: number;
        awaiting_assessments_count: number;
    };
}

export interface ComplianceDetailsSnapshot {
    month: MonthTimeframe;
    timestamp: string;
    details: ComplianceDetails;
}

export interface ComplianceDetails {
    regulation: string;
    met: string[];
    not_met: string[];
    not_mapped: string[];
    awaiting_assessments: string[];
}

export enum FFIECCATMaturityLevel {
    BASELINE = 'Baseline',
    EVOLVING = 'Evolving',
    INTERMEDIATE = 'Intermediate',
    ADVANCED = 'Advanced',
    INNOVATIVE = 'Innovative',
}

export const targetMaturitySelectOptions = Object.keys(FFIECCATMaturityLevel).map((maturity) => ({
    label: FFIECCATMaturityLevel[maturity as keyof typeof FFIECCATMaturityLevel],
    value: FFIECCATMaturityLevel[maturity as keyof typeof FFIECCATMaturityLevel],
}));

export interface SetTargetMaturityFFIECCATRequest {
    target_maturity: FFIECCATMaturityLevel;
}

export interface DomainStatusFFIECCAT {
    name: string;
    met_requirements_count: number;
    total_requirements_count: number;
}

export interface ComplianceHistoryFFIECCAT {
    periodic_summaries: ComplianceSummarySnapshot[];
    current_domain_statuses: DomainStatusFFIECCAT[];
    current_met_requirements_count: number;
    current_total_requirements_count: number;
}

export interface UpcomingAssessmentsForMonth {
    month: MonthTimeframe;
    assessments: UncompletedAssessment[];
}

export interface AssessmentsResponse {
    overdue: {
        marginally: UncompletedAssessment[];
        exceedingly: UncompletedAssessment[];
    };
    due_soon: UncompletedAssessment[];
    completed_recently: CompletedAssessment[];
    upcoming_by_month: UpcomingAssessmentsForMonth[];
}

export enum DashboardType {
    OPERATIONAL_CONTROLS = 'operational_controls',
    THIRD_PARTY = 'third_party',
}
