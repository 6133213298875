import { useState } from 'react';

import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { Text } from 'Components/Text/Text';
import { ControlGroupResponse, ControlListingView, DetailedControlFrameworkResponse } from 'Models/OperationalControls';

import { ControlGroupGridView } from './ControlGroupGridView/ControlGroupGridView';
import { ControlGroupListView } from './ControlGroupListView/ControlGroupListView';
import { ControlGroupListingBodyToolbar, ControlGroupListingBodyToolbarProps } from './ControlGroupListingBodyToolbar/ControlGroupListingBodyToolbar';

export interface IndependentControlListingProps {
    controlGroupResponse?: ControlGroupResponse[];
    detailedControlFrameworkResponse?: DetailedControlFrameworkResponse;
}

export const IndependentControlListing: React.FC<IndependentControlListingProps> = (props) => {
    const [activeControlGroupListingView, setActiveControlGroupListingView] = useState<ControlListingView>(ControlListingView.GRID);

    const switchControlListingView = (activeControlGroupListingView: ControlListingView): void => {
        setActiveControlGroupListingView(activeControlGroupListingView);
    };

    if (props.controlGroupResponse && props.detailedControlFrameworkResponse) {
        const controlListingBodyToolbarProps: ControlGroupListingBodyToolbarProps = {
            switchListingView: switchControlListingView,
            activeControlListingView: activeControlGroupListingView,
        };

        const controlGroupListingView = activeControlGroupListingView;

        if (props.controlGroupResponse.length === 0) {
            return (
                <PageContent>
                    <Text>There are no enabled control groups.</Text>
                </PageContent>
            );
        } else {
            return (
                <>
                    <ControlGroupListingBodyToolbar {...controlListingBodyToolbarProps} />
                    {{ list: <ControlGroupListView controlGroups={props.controlGroupResponse} />, grid: <ControlGroupGridView controlGroupResponse={props.controlGroupResponse} /> }[controlGroupListingView]}
                </>
            );
        }
    }
    return null;
};
