export const ACTIONS_LINK = 'An optional hyperlink that supports the description and/or status of the action.';
export const ACTIONS_OWNER = 'The individual responsible for the action. Only users with access to Risk Management can own actions.';

export const ISSUES_EXCEPTIONS_REFERENCE = 'An optional reference to an external system, such as an ID, ticket number, or URL.';
export const ISSUE_SOURCE = 'The origin of the issue, such as "Audit" or "PenTest".';
export const EXCEPTIONS_COMPENSATING_CONTROLS = 'Any compensating or alternative controls that reduce the risk introduced by this exception.';

export const TPRM_IRQ_ADDITIONAL_INFORMATION = 'Additional information related to determining the inherent risk rating.';
export const TPRM_IRQ_FILES = 'Documents related to determining the inherent risk rating.';

// Answers to IRQ questions:
export const TPRM_IRQ_DATA_CLASSIFICATION_CONFIDENTIAL = "PII such as name, address, email address, phone number, driver's license, etc.";
export const TPRM_IRQ_DATA_CLASSIFICATION_INTERNAL = 'Office phone number, organization charts, etc.';
export const TPRM_IRQ_DATA_CLASSIFICATION_PUBLIC = 'Marketing material, brochures, press releases, etc.';
export const TPRM_IRQ_DATA_CLASSIFICATION_RESTRICTED = 'SSN, credit card, account number, password, strategic information such as M&A, etc.';
export const TPRM_IRQ_SENSITIVE_DATA_STORAGE_OFFSHORE = 'The data resides outside the USA.';
export const TPRM_IRQ_SENSITIVE_DATA_STORAGE_ON_PREMISES = "Data does not leave my organization's physical property or network.";
export const TPRM_IRQ_SENSITIVE_DATA_STORAGE_ON_PREMISES_REMOTE_ACCESS = "Data is stored on my organization's network, but the third party accesses it remotely.";
export const TPRM_IRQ_SENSITIVE_DATA_STORAGE_ONSHORE = "Data resides on the third party's network within the USA.";
export const TPRM_IRQ_SENSITIVE_DATA_STORAGE_ONSHORE_CLOUD_HOSTED = 'The third party manages the data within a fourth-party cloud service, physically located in the USA.';

// TODO: Clean these up. Make sure the wordings fit our standards, and remove any unnecessary tooltips.
export const TPRM_THIRD_PARTY_ADDRESS = 'The address of this third party.';
export const TPRM_THIRD_PARTY_CITY = 'The city in which this third party resides.';
export const TPRM_THIRD_PARTY_CONTACT_EMAIL = 'The email address of the contact at this third party.';
export const TPRM_THIRD_PARTY_CONTACT_NAME = 'The name of the contact at this third party.';
export const TPRM_THIRD_PARTY_CONTACT_PHONE_NUMBER = 'The phone number of the contact at this third party.';
export const TPRM_THIRD_PARTY_EIN = 'The unique identifier for the company, such as the EIN/TIN issued by the IRS.';
export const TPRM_THIRD_PARTY_MANAGER = 'The individual responsible for continuous oversight of the third party.';
export const TPRM_THIRD_PARTY_NAME = 'The full legal name of the third party.';
export const TPRM_THIRD_PARTY_WEBSITE = 'The website URL for the third party.';
export const TPRM_THIRD_PARTY_ZIP_CODE = 'The zip code in which this third party resides.';
