import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { IconButton } from 'Components/Buttons/IconButton';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { ScheduleFrequency } from 'Models/ScheduleFrequency';
import { RiskRatingAsString, Service } from 'Models/TPRM';

import styles from './SubmitThirdPartyServiceAssessmentConfirmationModal.module.css';

export interface SubmitThirdPartyServiceAssessmentConfirmationModalProps {
    hideModal: () => void;
    service: Service;
    serviceAssessmentDueDate?: Date;
    onServiceAssessmentDueDateChanged: (dueDate?: Date) => void;
    onConfirm: () => void;
}

/**
 * Asks the user to confirm that they want to submit the service assessment.
 * The user also has the option of scheduling the next assessment before submitting.
 *
 * TODO: This modal breaks our standards. It is immediately closed after the submit button is pressed; the network request and messaging are handled by the parent/grandparent. Use our `ConfirmationModal` component here for consistency.
 */
export const SubmitThirdPartyServiceAssessmentConfirmationModal = (props: SubmitThirdPartyServiceAssessmentConfirmationModalProps): JSX.Element => {
    const handleChangeDate = (date: Date): void => {
        props.onServiceAssessmentDueDateChanged(date ?? undefined);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.onConfirm();
    };

    const getScheduleFrequencyText = (scheduleFrequency: ScheduleFrequency) => {
        switch (scheduleFrequency) {
            case ScheduleFrequency.DAYS:
                return 'day(s)';
            case ScheduleFrequency.MONTHS:
                return 'month(s)';
            case ScheduleFrequency.YEARS:
                return 'year(s)';
        }
    };

    const tooltip = 'If this field is left blank, then no assessment due date will be set.';

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Submit Third-Party Service Assessment" />

                    <Text>
                        <b>{`Are you sure you want to submit the assessment for "${props.service.name}"?`}</b>
                    </Text>

                    <Text>Submitting the third-party service assessment will complete the risk workflow and the risk workflow will be archived. The third-party service contact(s) will no longer be able to access the third-party questionnaire. After archival, the option to begin the next risk workflow will be available on the service dashboard.</Text>

                    {props.service.assessment_schedule ? (
                        <Text>
                            Based on a {RiskRatingAsString(props.service.inherent_risk_score)} inherent risk rating, the frequency for conducting a service assessment of {props.service.name} is every {props.service.assessment_schedule.schedule_number} {getScheduleFrequencyText(ScheduleFrequency[props.service.assessment_schedule.schedule_frequency])}.
                        </Text>
                    ) : (
                        <Text>
                            Based on a {RiskRatingAsString(props.service.inherent_risk_score)} inherent risk rating, there is no recurring service assessment for {props.service.name}.
                        </Text>
                    )}

                    <div className={styles.datePickerAndClearIcon}>
                        <div className={styles.datePicker}>
                            <FormFieldDatePicker dateFormat="MM/dd/yyyy" selected={props.serviceAssessmentDueDate} handleChange={handleChangeDate} formFieldId="nextAssessmentDate" formFieldLabel="Next Assessment Date" placeholder={'MM/DD/YYYY'} invalidMessage={'Please enter a valid date (MM/DD/YYYY)'} tooltip={tooltip} />
                        </div>
                        <div className={styles.clearIcon}>
                            <IconButton aria-label="clear-date" onClick={() => props.onServiceAssessmentDueDateChanged(undefined)} fontAwesomeImage={faTrash} />
                        </div>
                    </div>

                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="submit">SUBMIT</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
