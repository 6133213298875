import { faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash-es';
import { useState } from 'react';

import { UsersApi } from 'Api/Users/UsersApi';
import { Button } from 'Components/Buttons/Buttons';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { RBACComponent } from 'Components/Context/RBACComponent';
import { Role } from 'Components/Context/RBACContext';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Page } from 'Components/Page/Page';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { CONFIGURATION } from 'Config/Paths';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { Filter, Filters, GroupOptionType, GroupedOptions } from 'Models/Types/GlobalType';
import { ManageUsersSortFilterOptions, Status, UserResponse } from 'Models/User';

import { DeactivateUserModal, DeactivateUserModalProps } from './DeactivateUserModal/DeactivateUserModal';
import { DeleteUserModal, DeleteUserModalProps } from './DeleteUserModal/DeleteUserModal';
import { ManageUserModal, ManageUserModalProps } from './ManageUserModal/ManageUserModal';
import styles from './ManageUsers.module.css';
import { ManageUsersTableView, ManageUsersTableViewProps } from './ManageUsersTableView/ManageUsersTableView';
import { ManageUsersFilter, ManageUsersFilterProps } from './MangeUsersFilter/ManageUsersFilter';
import { ReactivateUserModal, ReactivateUserModalProps } from './ReactivateUserModal/ReactivateUserModal';
import { RoleDescriptionsModal } from './RoleDescriptionsModal/RoleDescriptionsModal';

export interface ManageUsersProps {
    usersApi: UsersApi;
}

enum Modal {
    None,
    Create,
    Delete,
    Deactivate,
    Reactivate,
    Modify,
    RoleDescriptions,
}

export const ManageUsers = (props: ManageUsersProps) => {
    const cachedData = useCachedData();
    const [displayedModal, setDisplayedModal] = useState<Modal>(Modal.None);
    const [currentSort, setCurrentSort] = useState<string>(ManageUsersSortFilterOptions.LAST_NAME);
    const [currentSortDirection, setCurrentSortDirection] = useState<SortDirection>(SortDirection.ASC);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<Filters>({});
    const [selectedUserForDeletion, setSelectedUserForDeletion] = useState<UserResponse>();
    const [selectedUserForDeactivation, setSelectedUserForDeactivation] = useState<UserResponse>();
    const [selectedUserForReactivation, setSelectedUserForReactivation] = useState<UserResponse>();
    const [selectedUserForModification, setSelectedUserForModification] = useState<UserResponse>();

    const userSelectedForDeletion = (selectedUserForDeletion: UserResponse): void => {
        setSelectedUserForDeletion(selectedUserForDeletion);
        setDisplayedModal(Modal.Delete);
    };

    const userSelectedForDeactivation = (selectedUserForDeactivation: UserResponse): void => {
        setSelectedUserForDeactivation(selectedUserForDeactivation);
        setDisplayedModal(Modal.Deactivate);
    };

    const userSelectedForReactivation = (selectedUserForReactivation: UserResponse): void => {
        setSelectedUserForReactivation(selectedUserForReactivation);
        setDisplayedModal(Modal.Reactivate);
    };

    const userSelectedForModification = (selectedUserForModification: UserResponse): void => {
        setSelectedUserForModification(selectedUserForModification);
        setDisplayedModal(Modal.Modify);
    };

    const applySorting = (newSort: string, newSortDirection: SortDirection): void => {
        setCurrentSort(newSort as ManageUsersSortFilterOptions);
        setCurrentSortDirection(newSortDirection);
    };

    const filterAndSortUsers = (): UserResponse[] => {
        if (!cachedData.users) {
            return [];
        }

        let userList = [...cachedData.users];

        if (Object.keys(selectedFilterOptions).length > 0) {
            for (const filter of Object.values(selectedFilterOptions)) {
                userList = userList.filter((user) => {
                    if (filter.key === ManageUsersSortFilterOptions.ROLES) {
                        return filter.value.filter((role) => user.roles.includes(Role[role as keyof typeof Role])).length > 0;
                    } else if (filter.key === ManageUsersSortFilterOptions.MFA_REQUIRED) {
                        return filter.value.includes(user[filter.key].toString());
                    }
                    return filter.value.includes(user[filter.key] as string);
                });
            }
        }

        if (!currentSort) {
            return userList;
        }

        return userList.sort((userA, userB) => {
            let sortResult = 0;

            switch (currentSort) {
                case ManageUsersSortFilterOptions.LAST_NAME:
                    sortResult = compareUsersBySubjectForSorting(userA.cognito_subject, userB.cognito_subject, cachedData.users || []);
                    break;
                case ManageUsersSortFilterOptions.MFA_REQUIRED:
                    sortResult = userA[currentSort].toString().localeCompare(userB[currentSort].toString()) * -1; // Multiply by -1 to reverse the sorting because the values being compared are "true" or "false" whereas the values the user sees are "ENABLED" or "DISABLED".
                    break;
                default:
                    sortResult = (userA[currentSort] as string).localeCompare(userB[currentSort] as string);
                    break;
            }

            return currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    const getFilterOptions = (users: UserResponse[]): GroupedOptions[] => {
        if (users === undefined) {
            return [];
        }

        const mfaRequiredOptions: GroupOptionType[] = [];
        mfaRequiredOptions.push({
            groupId: ManageUsersSortFilterOptions.MFA_REQUIRED,
            label: 'MFA Enabled',
            value: true.toString(),
        });
        mfaRequiredOptions.push({
            groupId: ManageUsersSortFilterOptions.MFA_REQUIRED,
            label: 'MFA Disabled',
            value: false.toString(),
        });

        const roleOptions: GroupOptionType[] = [];
        for (const key of Object.keys(Role)) {
            roleOptions.push({
                groupId: ManageUsersSortFilterOptions.ROLES,
                label: key,
                value: key,
            });
        }

        const statusOptions: GroupOptionType[] = [];
        for (const [key, value] of Object.entries(Status)) {
            statusOptions.push({
                groupId: ManageUsersSortFilterOptions.STATUS,
                label: key,
                value: value,
            });
        }

        const departments: Set<string> = new Set();
        for (const user of users) {
            departments.add(user.department);
        }

        const departmentOptions: GroupOptionType[] = [];
        for (const department of departments.values()) {
            departmentOptions.push({
                groupId: ManageUsersSortFilterOptions.DEPARTMENT,
                label: department,
                value: department,
            });
        }

        return [
            {
                label: 'Multi-Factor Authentication',
                options: mfaRequiredOptions,
            },
            {
                label: 'Role',
                options: roleOptions,
            },
            {
                label: 'Status',
                options: statusOptions,
            },
            {
                label: 'Department',
                options: departmentOptions,
            },
        ];
    };

    const filtersUpdated = (selectedFilters: Filter | Filter[]): void => {
        const currentFilterList = cloneDeep(selectedFilterOptions);

        if (selectedFilters instanceof Array) {
            selectedFilters.forEach((filter) => {
                if (filter.value.length > 0) {
                    currentFilterList[filter.key] = filter;
                } else {
                    delete currentFilterList[filter.key];
                }
            });
        } else {
            if (selectedFilters.value.length > 0) {
                currentFilterList[selectedFilters.key] = selectedFilters;
            } else {
                delete currentFilterList[selectedFilters.key];
            }
        }

        setSelectedFilterOptions(currentFilterList);
    };

    const getDeleteUserModalProps = (userSelectedForDeletion: UserResponse): DeleteUserModalProps => {
        return {
            hideModal: () => {
                setDisplayedModal(Modal.None);
                setSelectedUserForDeletion(undefined);
            },
            userDeleted: () => cachedData.refreshUsers(),
            usersApi: props.usersApi,
            userSelectedForDeletion: userSelectedForDeletion,
        };
    };

    const getDeactivateUserModalProps = (userSelectedForDeactivation: UserResponse): DeactivateUserModalProps => {
        return {
            hideModal: () => {
                setDisplayedModal(Modal.None);
                setSelectedUserForDeactivation(undefined);
            },
            userDeactivated: () => cachedData.refreshUsers(),
            usersApi: props.usersApi,
            userSelectedForDeactivation: userSelectedForDeactivation,
        };
    };

    const getReactivateUserModalProps = (userSelectedForReactivation: UserResponse): ReactivateUserModalProps => {
        return {
            hideModal: () => {
                setDisplayedModal(Modal.None);
                setSelectedUserForReactivation(undefined);
            },
            userReactivated: () => cachedData.refreshUsers(),
            usersApi: props.usersApi,
            userSelectedForReactivation: userSelectedForReactivation,
        };
    };

    if (cachedData.users) {
        const manageUserModalProps: ManageUserModalProps = {
            allExistingUsers: cachedData.users,
            hideModal: () => {
                setDisplayedModal(Modal.None);
                setSelectedUserForModification(undefined);
            },
            selectedUserForModification: selectedUserForModification,
            usersApi: props.usersApi,
            userCreated: () => cachedData.refreshUsers(),
            userModified: () => cachedData.refreshUsers(),
        };

        const manageUsersTableViewProps: ManageUsersTableViewProps = {
            users: filterAndSortUsers(),
            currentSort: currentSort,
            currentSortDirection: currentSortDirection,
            applySorting: applySorting,
            userSelectedForDeletion: userSelectedForDeletion,
            userSelectedForDeactivation: userSelectedForDeactivation,
            userSelectedForReactivation: userSelectedForReactivation,
            userSelectedForModification: userSelectedForModification,
        };

        const manageUsersFilterProps: ManageUsersFilterProps = {
            filterOptions: getFilterOptions(cachedData.users),
            filtersUpdated: filtersUpdated,
        };
        return (
            <RBACComponent roles={[Role.ADMIN]}>
                {(displayedModal === Modal.Create || displayedModal === Modal.Modify) && <ManageUserModal {...manageUserModalProps} />}
                {displayedModal === Modal.Delete && <DeleteUserModal {...getDeleteUserModalProps(selectedUserForDeletion!)} />}
                {displayedModal === Modal.Deactivate && <DeactivateUserModal {...getDeactivateUserModalProps(selectedUserForDeactivation!)} />}
                {displayedModal === Modal.RoleDescriptions && <RoleDescriptionsModal hideModal={() => setDisplayedModal(Modal.None)} />}
                {displayedModal === Modal.Reactivate && <ReactivateUserModal {...getReactivateUserModalProps(selectedUserForReactivation!)} />}
                <Page
                    headerBreadcrumb={
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${CONFIGURATION}`}>Settings</BreadcrumbLink>
                            <BreadcrumbText>Manage Users</BreadcrumbText>
                        </Breadcrumb>
                    }
                    headerTitle="Manage Users"
                    headerButtons={
                        <>
                            <Button variant="secondary" onClick={() => setDisplayedModal(Modal.RoleDescriptions)} fontAwesomeImage={faEye}>
                                ROLE DESCRIPTIONS
                            </Button>
                            <Button variant="primary" onClick={() => setDisplayedModal(Modal.Create)} fontAwesomeImage={faPlus}>
                                CREATE USER
                            </Button>
                        </>
                    }
                    headerDescription="Create and modify user accounts. Assign roles to users to control access to SummIT Security features and data."
                    body={[
                        {
                            content: (
                                <>
                                    <div className={styles.filterContainer}>
                                        <ManageUsersFilter {...manageUsersFilterProps} />
                                    </div>
                                    <div className={styles.tableContainer}>
                                        <ManageUsersTableView {...manageUsersTableViewProps} />
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            </RBACComponent>
        );
    } else {
        return <Placeholder />;
    }
};
