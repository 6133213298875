import { faBars, faThLarge } from '@fortawesome/free-solid-svg-icons';

import { IconButton } from 'Components/Buttons/IconButton';
import { ServiceMultiOptionFilter } from 'Components/Filters/ServiceMultiOptionFilter/ServiceMultiOptionFilter';
import { UserFilter } from 'Components/Filters/UserFilter/UserFilter';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { ServiceListingSortFilterOptions } from 'Models/TPRM';
import { Filter, GroupOptionType, GroupedOptions, OptionType } from 'Models/Types/GlobalType';

import styles from './ServiceListingBodyToolbar.module.css';
import { ServiceListingView } from '../ServiceListing';

export interface ServiceListingBodyToolbarProps {
    switchListingView: (activeServiceListingView: ServiceListingView) => void;
    activeServiceListingView: ServiceListingView;
    filterOptions: GroupedOptions[];
    defaultSelectedOptions?: GroupOptionType[];
    thirdPartyFilter: (selectedFilterOptions: Filter | Filter[]) => void;
    sortCardsBy: (sortProperty: string, sortDirection: SortDirection) => void;
    currentSort: string;
}

const ServiceListingSortOptions: OptionType[] = [
    {
        label: 'Assessment Due Date',
        value: ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE,
    },
    {
        label: 'Date Created',
        value: ServiceListingSortFilterOptions.DATE_CREATED,
    },
    {
        label: 'Residual Risk',
        value: ServiceListingSortFilterOptions.RESIDUAL_RISK,
    },
    {
        label: 'Risk Rating',
        value: ServiceListingSortFilterOptions.INHERENT_RISK,
    },
    {
        label: 'Service',
        value: ServiceListingSortFilterOptions.SERVICE,
    },
    {
        label: 'Service Manager',
        value: ServiceListingSortFilterOptions.THIRD_PARTY_SERVICE_MANAGER_USER_ID,
    },
    {
        label: 'Third Party',
        value: ServiceListingSortFilterOptions.THIRD_PARTY,
    },
];

export const ServiceListingBodyToolbar = (props: ServiceListingBodyToolbarProps) => {
    const handleSelectChange = (value: ChangeEventType): void => {
        const sortOption = value as ServiceListingSortFilterOptions;
        if (sortOption) {
            props.sortCardsBy(sortOption, SortDirection.ASC);
        }
    };

    return (
        <div className={styles.thirdPartyListingFilter}>
            <div className={styles.filter}>
                {props.activeServiceListingView === ServiceListingView.GRID && (
                    <div className={styles.sortSelect}>
                        <FormFieldSelect options={ServiceListingSortOptions} handleChange={handleSelectChange} formFieldId="isSortingCards" formFieldLabel="Sort" selectedOption={props.currentSort} />
                    </div>
                )}
                <div className={styles.filterSelectServiceManager}>
                    <UserFilter filterLabel="Filter by Service Manager" onUsersSelected={props.thirdPartyFilter} userFilterId="thirdPartyManagerId" formFieldId="serviceManagerFilter" />
                </div>
                <div className={styles.filterSelectServiceAttributes}>
                    <ServiceMultiOptionFilter filterOptions={props.filterOptions} defaultSelectedOptions={props.defaultSelectedOptions} thirdPartyFilter={props.thirdPartyFilter} />
                </div>
            </div>
            <div className={styles.tableAndGridButtonPanel}>
                <IconButton aria-label="Switch to Service Grid View" onClick={() => props.switchListingView(ServiceListingView.GRID)} fontAwesomeImage={faThLarge} disabled={props.activeServiceListingView === ServiceListingView.GRID} />
                <IconButton aria-label="Switch to Service List View" onClick={() => props.switchListingView(ServiceListingView.LIST)} fontAwesomeImage={faBars} disabled={props.activeServiceListingView === ServiceListingView.LIST} />
            </div>
        </div>
    );
};
