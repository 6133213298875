import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TrendLineChart } from 'Components/BaseCharts/TrendLineChart';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { Text } from 'Components/Text/Text';
import { iso8601ToUsDateLong } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { formatImpactOrProbability, formatTotalImpactOrProbability } from 'Models/RiskRegister';

import styles from './RiskCell.module.css';

export interface RiskCellPropsInherentRisk {
    type: 'Inherent Risk';
    impact?: number;
    probability?: number;
    total?: number;

    /**
     * Up to five risk ratings for a risk, where the most recent rating is the last element in the array.
     */
    trend: { value: number; timestamp: string }[];
}

export interface RiskCellPropsCurrentRisk {
    type: 'Current Residual Risk';
    impact?: number;
    probability?: number;
    total?: number;

    /**
     * Up to five risk ratings for a risk, where the most recent rating is the last element in the array.
     */
    trend: { value: number; timestamp: string }[];
}

export interface RiskCellPropsTargetRisk {
    type: 'Target Residual Risk';
    impact?: number;
    probability?: number;
    total?: number;
}

/**
 * Renders a cell with information about a Risk's Inherent Risk, Current Residual Risk, or Target Residual Risk.
 */
export const RiskCell = (props: RiskCellPropsInherentRisk | RiskCellPropsCurrentRisk | RiskCellPropsTargetRisk) => {
    const trendLineChart = (() => {
        switch (props.type) {
            case 'Target Residual Risk':
                return undefined;
            default:
                return (
                    <div className={styles.headerAndInfo}>
                        <Text noStyles variant="Header4" color="darkGray">
                            TREND
                        </Text>
                        {props.trend.length > 1 ? (
                            <div className={styles.trendLineChart}>
                                <TrendLineChart data={[...props.trend].map((element) => ({ value: element.value, xAxisLabel: iso8601ToUsDateLong(element.timestamp) }))} />
                            </div>
                        ) : (
                            <Text variant="Text1" color="white">
                                -
                            </Text>
                        )}
                    </div>
                );
        }
    })();

    const lastTwoValues = (() => {
        switch (props.type) {
            case 'Target Residual Risk':
                return undefined;
            default:
                if (props.trend.length < 2) {
                    return undefined;
                } else {
                    return props.trend.slice(-2).map((element) => element.value);
                }
        }
    })();

    const trendDirection = (() => {
        if (!lastTwoValues || lastTwoValues[0] === lastTwoValues[1]) {
            return undefined;
        } else {
            return lastTwoValues[0] > lastTwoValues[1] ? 'down' : 'up';
        }
    })();

    const impactLabel = (() => {
        switch (props.type) {
            case 'Inherent Risk':
                return 'INITIAL IMPACT';
            case 'Current Residual Risk':
                return 'CURRENT IMPACT';
            case 'Target Residual Risk':
                return 'TARGET IMPACT';
        }
    })();

    const probabilityLabel = (() => {
        switch (props.type) {
            case 'Inherent Risk':
                return 'INITIAL PROBABILITY';
            case 'Current Residual Risk':
                return 'CURRENT PROBABILITY';
            case 'Target Residual Risk':
                return 'TARGET PROBABILITY';
        }
    })();

    return (
        <PageCell variant="transparentBlue">
            <div className={styles.cell}>
                <div className={styles.cellHeader}>
                    <Text variant="Header2" color="white">
                        {props.type}
                    </Text>
                </div>
                <div className={styles.cellRow}>
                    <div className={styles.headerAndInfo}>
                        <Text noStyles variant="Header4" color="darkGray">
                            TOTAL
                        </Text>
                        <div className={styles.totalAndTrend}>
                            <Text noStyles variant="Text1" color="white">
                                {formatTotalImpactOrProbability(props.total)}
                            </Text>
                            {trendDirection !== undefined && <FontAwesomeIcon icon={trendDirection === 'down' ? faArrowDown : faArrowUp} className={trendDirection === 'down' ? styles.trendArrowDown : styles.trendArrowUp} />}
                        </div>
                    </div>
                    {trendLineChart}
                </div>
                <div className={styles.bottomCellRow}>
                    <div className={styles.headerAndInfo}>
                        <Text noStyles variant="Header4" color="darkGray">
                            {impactLabel}
                        </Text>
                        <Text noStyles variant="Text1" color="white">
                            {formatImpactOrProbability(props.impact)}
                        </Text>
                    </div>
                    <div className={styles.headerAndInfo}>
                        <Text noStyles variant="Header4" color="darkGray">
                            {probabilityLabel}
                        </Text>
                        <Text noStyles variant="Text1" color="white">
                            {formatImpactOrProbability(props.probability)}
                        </Text>
                    </div>
                </div>
            </div>
        </PageCell>
    );
};
