import { Action } from 'Models/Actions';
import { ExceptionEntity } from 'Models/Exceptions';
import { AssessmentState, OperationalControl } from 'Models/OperationalControls';
import { FolderVersion, Service, ThirdParty } from 'Models/TPRM';

import { Issue } from './Issues';

export enum NotificationType {
    ACTION_DUE = 'Action Due',
    CONTROL_ASSESSMENT = 'Control Assessment',
    EXCEPTION_EXPIRY = 'Exception Expiry',
    FOLDER_EXPIRY = 'Folder Expiry',
    ISSUE_DUE = 'Issue Due',
    SERVICE_ASSESSMENT_DUE = 'Service Assessment Due',
}

/**
 * This defines common properties that all Notification types have.
 */
interface UserNotificationBase {
    due_date: string;
    identifier: string;
}

export interface ActionDueNotification extends UserNotificationBase {
    _type: NotificationType.ACTION_DUE;
    action: Action;
}

export interface ControlAssessmentDueNotification extends UserNotificationBase {
    _type: NotificationType.CONTROL_ASSESSMENT;
    control: OperationalControl;
    status: AssessmentState;
}

export interface ExceptionExpiryNotification extends UserNotificationBase {
    _type: NotificationType.EXCEPTION_EXPIRY;
    exception: ExceptionEntity;
}

export interface FolderExpiryNotification extends UserNotificationBase {
    _type: NotificationType.FOLDER_EXPIRY;
    folder: FolderVersion;
    vendor: ThirdParty;
}

export interface IssueDueNotification extends UserNotificationBase {
    _type: NotificationType.ISSUE_DUE;
    issue: Issue;
}

export interface ThirdPartyServiceAssessmentDueNotification extends UserNotificationBase {
    _type: NotificationType.SERVICE_ASSESSMENT_DUE;
    vendor: ThirdParty;
    vendor_service: Service;
}

export type UserNotification = ActionDueNotification | ControlAssessmentDueNotification | ExceptionExpiryNotification | FolderExpiryNotification | IssueDueNotification | ThirdPartyServiceAssessmentDueNotification;
