import { faEquals } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { Text } from 'Components/Text/Text';

import styles from './ControlDistribution.module.css';
import { SelectedControl } from '../ManageRisk';

export interface ControlDistributionProps {
    controls: SelectedControl[];
    totalWeight: number;
    warningMessage?: string;
    changeWeights: (weights: number[]) => void;
}

export const ControlDistribution = (props: ControlDistributionProps): JSX.Element => {
    const changeControlWeight = (controlIdentifier: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const weight = Number(event.target.value);
        const newWeights = props.controls.map((control) => (control.identifier === controlIdentifier ? weight : control.weight));
        props.changeWeights(newWeights);
    };

    const equalizeControlWeights = () => {
        const equalPercentages = calculateEqualPercentages(props.controls.length);
        props.changeWeights(equalPercentages);
    };

    return (
        <>
            {props.controls.map((control) => (
                <div className={styles.controlRow} key={control.identifier}>
                    <div className={styles.controlTextContainer}>
                        <Text noStyles variant="Text2">
                            {control.name}
                        </Text>
                        <Text variant="Text4" noStyles>
                            {control.description}
                        </Text>
                    </div>
                    <div className={styles.textContainer}>
                        <FormControl aria-label={`${control.name} percentage`} name={`${control.identifier}_distribution_percentage`} className={styles.customFormControl} type="number" onChange={changeControlWeight(control.identifier)} value={control.weight} min={0} max={100} step={1} />
                        <Text variant="Text2" noStyles>
                            %
                        </Text>
                    </div>
                </div>
            ))}
            <div className={styles.totalContainer}>
                <Text noStyles variant="Text2">
                    {`Total: ${props.totalWeight}%`}
                </Text>
                {props.warningMessage && (
                    <Text noStyles variant="Text3" color="red">
                        {props.warningMessage}
                    </Text>
                )}
                <div className={styles.equalizeButton}>
                    <Button variant="secondary" onClick={equalizeControlWeights} fontAwesomeImage={faEquals}>
                        Equalize Distribution
                    </Button>
                </div>
            </div>
        </>
    );
};

export const calculateEqualPercentages = (count: number): number[] => {
    if (count === 0) {
        return [];
    }

    const equalValueWithoutRemainder = Math.floor(100 / count);
    const equalPercentages = Array<number>(count).fill(equalValueWithoutRemainder);

    let remainder = 100 % count;
    let index = 0;
    while (remainder > 0) {
        equalPercentages[index] += 1;
        remainder -= 1;
        index += 1;
    }

    return equalPercentages;
};
