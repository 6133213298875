import { faCalendar, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { OverflowMenu, OverflowMenuProps } from 'Components/Buttons/OverflowMenu';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { HeaderData, SortDirection, SortableTableHeader, SortableTableHeaderProps } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Table, TableBody, TableCell, TableCellDefaultText, TableCellLink, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { iso8601ToUsDateShort } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { getUserNameFromSubject } from 'Helpers/UserUtils';
import { ServiceListingSortFilterOptions } from 'Models/TPRM';

import styles from './ServiceListingTableView.module.css';
import { ServiceDisplayData } from '../ServiceListing';
import { TableRiskStatusBar } from './TableRiskStatusBar/TableRiskStatusBar';

export interface ServiceListingTableViewProps {
    serviceRows: ServiceDisplayData[];
    applySorting: (sortProperty: string, sortDirection: SortDirection) => void;
    selectedDeleteService: (service: ServiceDisplayData) => void;
    selectedModifyService: (service: ServiceDisplayData) => void;
    selectedServiceAssessmentDueDateService: (service: ServiceDisplayData) => void;
    currentSort: string;
    currentSortDirection: SortDirection;
}

export const ServiceListingTableView = (props: ServiceListingTableViewProps) => {
    const cachedData = useCachedData();

    const headerValues: HeaderData[] = [
        { dataKey: ServiceListingSortFilterOptions.THIRD_PARTY, label: 'THIRD PARTY' },
        { dataKey: ServiceListingSortFilterOptions.SERVICE, label: 'SERVICE' },
        { dataKey: ServiceListingSortFilterOptions.INHERENT_RISK, label: 'INHERENT RISK' },
        { dataKey: ServiceListingSortFilterOptions.RESIDUAL_RISK, label: 'RESIDUAL RISK' },
        { dataKey: ServiceListingSortFilterOptions.THIRD_PARTY_SERVICE_MANAGER_USER_ID, label: 'THIRD-PARTY SERVICE MANAGER' },
        { dataKey: ServiceListingSortFilterOptions.ASSESSMENT_DUE_DATE, label: 'ASSESSMENT DUE DATE' },
        { dataKey: ServiceListingSortFilterOptions.DATE_CREATED, label: 'DATE CREATED' },
    ];

    const tableRow = (serviceRow: ServiceDisplayData, index: number): JSX.Element => {
        const overflowMenuProps: OverflowMenuProps = {
            overflowItems: [
                {
                    text: 'Update Service',
                    onClickAction: () => props.selectedModifyService(props.serviceRows[index]),
                    icon: faEdit,
                },
                {
                    text: 'Set Assessment Due Date',
                    onClickAction: () => props.selectedServiceAssessmentDueDateService(props.serviceRows[index]),
                    icon: faCalendar,
                },
                {
                    text: 'Delete Service',
                    onClickAction: () => props.selectedDeleteService(props.serviceRows[index]),
                    icon: faTrash,
                },
            ],
            accessibilityTitle: `${serviceRow.serviceName} Menu`,
        };

        return (
            <TableRow key={index}>
                <TableCellDefaultText>
                    <Text noStyles={true}>{serviceRow.thirdPartyName}</Text>
                </TableCellDefaultText>
                <TableCellLink link={`/${TPRM}/${THIRD_PARTIES}/${serviceRow.thirdPartyId}/${SERVICES}/${serviceRow.serviceID}/${DASHBOARDS}`}>{serviceRow.serviceName}</TableCellLink>
                <TableCell>
                    <TableRiskStatusBar risk={serviceRow.inherentRisk} />
                </TableCell>
                <TableCell>
                    <TableRiskStatusBar risk={serviceRow.residualRisk ? serviceRow.residualRisk : 0} />
                </TableCell>
                <TableCellDefaultText>
                    <Text noStyles={true}>{getUserNameFromSubject(serviceRow.thirdPartyManagerId, cachedData.users)}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{serviceRow.assessmentDueDate ? iso8601ToUsDateShort(serviceRow.assessmentDueDate) : undefined}</Text>
                </TableCellDefaultText>
                <TableCellDefaultText>
                    <Text noStyles={true}>{iso8601ToUsDateShort(serviceRow.dateCreated)}</Text>
                </TableCellDefaultText>
                <TableOverflowCell>
                    <OverflowMenu {...overflowMenuProps} />
                </TableOverflowCell>
            </TableRow>
        );
    };

    const sortableTableProps: SortableTableHeaderProps = {
        headers: headerValues,
        applySorting: props.applySorting,
        currentSort: props.currentSort,
        currentSortDirection: props.currentSortDirection,
        tableIncludesOverflowMenu: true,
    };

    return (
        <div className={styles.riskTable}>
            <Table>
                <SortableTableHeader {...sortableTableProps} />
                <TableBody>{props.serviceRows.map(tableRow)}</TableBody>
            </Table>
        </div>
    );
};
