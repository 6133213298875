import { faCheck, faChevronCircleRight, faDownload, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Form } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowMenu } from 'Components/Buttons/OverflowMenu';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { FileDragAndDrop, FileDragAndDropProps } from 'Components/FileDragAndDrop/FileDragAndDrop';
import { FormFieldDatePicker } from 'Components/FormField/FormFieldDatePicker/FormFieldDatePicker';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldText } from 'Components/FormField/FormFieldText/FormFieldText';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { FormFieldUserMultiSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserMultiSelect';
import { FormFieldUserSelect } from 'Components/FormField/FormFieldUserSelect/FormFieldUserSelect';
import { ConfirmationModal } from 'Components/Modal/ConfirmationModal';
import { MultipleControlMapping } from 'Components/MultipleControlMapping/MultipleControlMapping';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Page } from 'Components/Page/Page';
import { Table, TableBody, TableCell, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { LinkButtonToast, TextToast } from 'Components/Toast/Toast';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';
import { EXCEPTIONS, ISSUES_EXCEPTIONS, TPRM } from 'Config/Paths';
import { EXCEPTIONS_COMPENSATING_CONTROLS, ISSUES_EXCEPTIONS_REFERENCE } from 'Config/Tooltips';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { downloadDocument, submitRequestWithFiles } from 'Helpers/FileUtils';
import { getExceptionDetailsUrl } from 'Helpers/URLBuilder/URLBuilder';
import { useFileDragAndDrop } from 'Hooks/FileDragAndDrop';
import { ControlExceptionResponse, CreateExceptionRequest, ExceptionImpact, ExceptionImpactOptions, ExceptionLikelihood, ExceptionLikelihoodOptions, ExceptionResponse, ExceptionStatus, ThirdPartyExceptionResponse, UpdateExceptionRequest } from 'Models/Exceptions';
import { UploadedFile } from 'Models/Files';
import { IssuesExceptionsModule } from 'Models/Issues';
import { OperationalControl } from 'Models/OperationalControls';
import { ThirdPartyResponse } from 'Models/TPRM';
import { UserResponse } from 'Models/User';

import styles from './ManageExceptionForm.module.css';

export type ManageExceptionFormProps = { exceptionsApi: ExceptionsApi; documentApi: DocumentApi } & (
    | {
          type: 'creatingControlException';
          controls: OperationalControl[];
          preselectedControlId?: string;
      }
    | {
          type: 'editingControlException';
          exception: ControlExceptionResponse;
          isDraftingForClosure: boolean;
          controls: OperationalControl[];
      }
    | {
          type: 'creatingThirdPartyException';
          thirdParties: ThirdPartyResponse[];
          preselectedThirdPartyId?: string;
      }
    | {
          type: 'editingThirdPartyException';
          exception: ThirdPartyExceptionResponse;
          isDraftingForClosure: boolean;
          thirdParty: ThirdPartyResponse;
      }
);

type InProgressAction = 'save' | 'approve' | 'close' | 'delete';

type CompletedAction =
    | {
          type: 'created';
          newException: ExceptionResponse;
      }
    | {
          type: 'updated';
          newException: ExceptionResponse;
      }
    | { type: 'deleted' };

type SubmissionState =
    | {
          request: 'waitingToSubmit';
      }
    | {
          request: 'inProgress';
          action: InProgressAction;
      }
    | {
          request: 'success';
          completedAction: CompletedAction;
          showToast: boolean;
      }
    | {
          request: 'failure';
          errorMessage: string;
          showToast: boolean;
      };

/**
 * Renders a page that can be used to create, update, or delete an exception in the Operational Controls module or in the TPRM module.
 * This component's parent (`CreateControlException`, `EditControlException`, `CreateThirdPartyException`, or `EditThirdPartyException`) is responsible for determining the type of exception being managed and for fetching the data necessary for rendering the page; this component is responsible for rendering the page and submitting create/update/delete network requests.
 */
export const ManageExceptionForm = (props: ManageExceptionFormProps): JSX.Element => {
    const cachedData = useCachedData();

    const [submissionState, setSubmissionState] = useState<SubmissionState>({ request: 'waitingToSubmit' });
    const exceptionWasDeleted = submissionState.request === 'success' && submissionState.completedAction.type === 'deleted';

    const existingException = props.type === 'editingControlException' || props.type === 'editingThirdPartyException' ? props.exception : undefined;
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false); // This is only used when the user is editing an existing exception.

    const [title, setTitle] = useState(existingException?.title);
    const [reviewer, setReviewer] = useState(cachedData.users.find((user) => user.cognito_subject === existingException?.reviewer_subject));
    const [owner, setOwner] = useState(cachedData.users.find((user) => user.cognito_subject === existingException?.owner_subject));
    const [delegates, setDelegates] = useState(existingException ? cachedData.users.filter((user) => existingException.delegates.includes(user.cognito_subject)) : []);
    const [likelihood, setLikelihood] = useState(existingException?.likelihood);
    const [impact, setImpact] = useState(existingException?.impact);
    const [expirationDateAsDate, setExpirationDateAsDate] = useState(existingException?.expiration_date ? new Date(existingException.expiration_date) : undefined);
    const [reference, setReference] = useState(existingException?.reference);
    const [compensatingControls, setCompensatingControls] = useState(existingException?.compensating_controls);
    const [description, setDescription] = useState(existingException?.description);
    const [remediationPlan, setRemediationPlan] = useState(existingException?.remediation_plan);
    const [riskAssessment, setRiskAssessment] = useState(existingException?.risk_assessment);
    const [closureStatement, setClosureStatement] = useState(existingException?.status === ExceptionStatus.DRAFT_CLOSE ? existingException.closure_statement : undefined);

    const [files, onAddFiles, onRemoveFile] = useFileDragAndDrop();
    const [existingFiles, setExistingFiles] = useState<UploadedFile[]>(existingException?.files ?? []);
    const [existingFilesToDelete, setExistingFilesToDelete] = useState<string[]>([]);

    const [impactedControls, setImpactedControls] = useState<string[]>(() => {
        switch (props.type) {
            case 'creatingControlException':
                return props.preselectedControlId ? [props.preselectedControlId] : [];
            case 'editingControlException':
                return props.exception.impacted_controls?.map((control) => control.identifier) ?? [];
            case 'creatingThirdPartyException':
                return [];
            case 'editingThirdPartyException':
                return [];
        }
    });

    const [impactedThirdParty, setImpactedThirdParty] = useState<string | undefined>(() => {
        switch (props.type) {
            case 'creatingControlException':
                return undefined;
            case 'editingControlException':
                return undefined;
            case 'creatingThirdPartyException':
                return props.preselectedThirdPartyId;
            case 'editingThirdPartyException':
                return props.exception.impacted_vendor;
        }
    });

    // Derive from state/props some values that are used multiple times in the requests/JSX below.
    const { exceptionType, headerText, needsClosureStatement, impactedThirdPartyOptions } = (() => {
        switch (props.type) {
            case 'creatingControlException':
                return {
                    exceptionType: IssuesExceptionsModule.CONTROLS,
                    headerText: 'Create Exception',
                    needsClosureStatement: false,
                    impactedThirdPartyOptions: undefined,
                };
            case 'creatingThirdPartyException':
                return {
                    exceptionType: IssuesExceptionsModule.TPRM,
                    headerText: 'Create Exception',
                    needsClosureStatement: false,
                    impactedThirdPartyOptions: props.thirdParties.map((thirdParty) => ({ value: thirdParty.id, label: thirdParty.name })),
                };
            case 'editingControlException':
                return {
                    exceptionType: IssuesExceptionsModule.CONTROLS,
                    headerText: 'Manage Exception',
                    needsClosureStatement: props.isDraftingForClosure,
                    impactedThirdPartyOptions: undefined,
                };
            case 'editingThirdPartyException':
                return {
                    exceptionType: IssuesExceptionsModule.TPRM,
                    headerText: 'Manage Exception',
                    needsClosureStatement: props.isDraftingForClosure,
                    impactedThirdPartyOptions: [{ value: props.thirdParty.id, label: props.thirdParty.name }],
                };
        }
    })();

    const handleRequestError = (error: Error): void => {
        setSubmissionState({ request: 'failure', errorMessage: error.message, showToast: true });
    };

    const toast: JSX.Element = (() => {
        switch (submissionState.request) {
            case 'waitingToSubmit':
            case 'inProgress':
                return <></>;
            case 'failure':
                return submissionState.showToast ? <TextToast variant="failure" clearToast={() => setSubmissionState({ ...submissionState, showToast: false })} text={submissionState.errorMessage} /> : <></>;
            case 'success':
                switch (submissionState.completedAction.type) {
                    case 'created':
                    case 'updated':
                        return submissionState.showToast ? <LinkButtonToast variant="success" clearToast={() => setSubmissionState({ ...submissionState, showToast: false })} linkButtonText="View exception" linkButtonTo={`${exceptionType === IssuesExceptionsModule.TPRM ? `/${TPRM}` : ''}/${EXCEPTIONS}/${submissionState.completedAction.newException.id}`} text="Exception saved." /> : <></>;
                    case 'deleted':
                        return <></>;
                }
        }
    })();

    const createException = async (): Promise<void> => {
        const validationFailureMessage = validateForm(ExceptionStatus.DRAFT_OPEN);
        if (validationFailureMessage) {
            setSubmissionState({ request: 'failure', errorMessage: validationFailureMessage, showToast: true });
            return;
        }

        setSubmissionState({ request: 'inProgress', action: 'save' });

        try {
            if (owner && reviewer && title && expirationDateAsDate && description && likelihood && impact && remediationPlan && riskAssessment) {
                await submitRequestWithFiles(
                    props.documentApi,
                    files.map((file) => ({ file })),
                    async (filesToBeUploaded) => {
                        const createExceptionRequest: CreateExceptionRequest = {
                            owner_subject: owner.cognito_subject,
                            reviewer_subject: reviewer.cognito_subject,
                            delegates: delegates.map((delegate) => delegate.cognito_subject),
                            title: title,
                            expiration_date: jsDateToIso8601(expirationDateAsDate),
                            description: description,
                            likelihood: likelihood,
                            impact: impact,
                            remediation_plan: remediationPlan,
                            risk_assessment: riskAssessment,
                            reference: reference,
                            compensating_controls: compensatingControls,
                            impacted_controls: impactedControls,
                            impacted_third_party: impactedThirdParty,
                            files: filesToBeUploaded,
                        };
                        const newException = (await props.exceptionsApi.createException(createExceptionRequest)).data;
                        setSubmissionState({ request: 'success', completedAction: { type: 'created', newException: newException }, showToast: true });
                    }
                );
            } else {
                throw new Error('Validation Exception');
            }
        } catch (error) {
            handleRequestError(error);
        }
    };

    const updateException = async (status: ExceptionStatus, action: InProgressAction): Promise<void> => {
        const validationFailureMessage = validateForm(status);
        if (validationFailureMessage) {
            setSubmissionState({ request: 'failure', errorMessage: validationFailureMessage, showToast: true });
            return;
        }

        setSubmissionState({ request: 'inProgress', action: action });

        try {
            if (owner && reviewer && title && expirationDateAsDate && description && likelihood && impact && remediationPlan && riskAssessment) {
                await submitRequestWithFiles(
                    props.documentApi,
                    files.map((file) => ({ file })),
                    async (filesToBeUploaded) => {
                        const updateExceptionRequest: UpdateExceptionRequest = {
                            owner_subject: owner.cognito_subject,
                            reviewer_subject: reviewer.cognito_subject,
                            delegates: delegates.map((delegate) => delegate.cognito_subject),
                            title: title,
                            expiration_date: jsDateToIso8601(expirationDateAsDate),
                            description: description,
                            likelihood: likelihood,
                            impact: impact,
                            remediation_plan: remediationPlan,
                            risk_assessment: riskAssessment,
                            reference: reference,
                            compensating_controls: compensatingControls,
                            impacted_controls: impactedControls,
                            impacted_third_party: impactedThirdParty,
                            file_updates: { new_files: filesToBeUploaded, existing_files_to_delete: existingFilesToDelete },
                            ...(status === ExceptionStatus.DRAFT_CLOSE || status === ExceptionStatus.CLOSED ? { status: status, closure_statement: closureStatement! } : { status: status }),
                        };

                        const newException = (await props.exceptionsApi.updateException(existingException!.id, updateExceptionRequest)).data;
                        setSubmissionState({ request: 'success', completedAction: { type: 'updated', newException: newException }, showToast: true });
                    }
                );
            } else {
                throw new Error('Validation Exception');
            }
        } catch (error) {
            handleRequestError(error);
        }
    };

    const deleteException = async (): Promise<string> => {
        setSubmissionState({ request: 'inProgress', action: 'delete' });
        await props.exceptionsApi.deleteException(existingException!.id);
        setSubmissionState({ request: 'success', completedAction: { type: 'deleted' }, showToast: false });
        return 'Exception deleted.';
    };

    const deleteConfirmationModal = (() => {
        if (!showDeleteConfirmationModal || !(props.type === 'editingControlException' || props.type === 'editingThirdPartyException')) {
            return undefined;
        }

        const informationalText = (() => {
            switch (props.type) {
                case 'editingControlException':
                    return 'The exception will be unmapped from any mapped controls.';
                case 'editingThirdPartyException':
                    return 'The exception will be unmapped from the third party.';
            }
        })();

        return (
            <ConfirmationModal operationType="delete" headerText="Delete Exception" areYouSureText={`Are you sure you want to delete "${props.exception.title}"?`} performOperation={deleteException} hideModal={() => setShowDeleteConfirmationModal(false)}>
                <Text>{informationalText}</Text>
            </ConfirmationModal>
        );
    })();

    /**
     * @returns a validation failure message if validation fails, or `undefined` if validation passes.
     * Note: There are ways we could use TS / helper functions to improve this tedious form validation, but this will change anyway once we start using react-hook-form.
     */
    const validateForm = (status: ExceptionStatus): string | undefined => {
        if (!title) {
            return 'Title is required.';
        } else if (!owner) {
            return 'Owner is required.';
        } else if (!reviewer) {
            return 'Reviewer is required.';
        } else if (!expirationDateAsDate) {
            return 'Expiration date is required.';
        } else if (!description) {
            return 'Description is required.';
        } else if (!likelihood) {
            return 'Likelihood is required.';
        } else if (!impact) {
            return 'Impact is required.';
        } else if (!remediationPlan) {
            return 'Remediation plan is required.';
        } else if (!riskAssessment) {
            return 'Risk assessment is required.';
        } else if ((props.type === 'creatingThirdPartyException' || props.type === 'editingThirdPartyException') && !impactedThirdParty) {
            return 'Third party impacted is required.';
        } else if ((status === ExceptionStatus.DRAFT_CLOSE || status === ExceptionStatus.CLOSED) && !closureStatement) {
            return 'Closure statement is required.';
        }
    };

    const buttons: JSX.Element = (() => {
        const inProgressAction = submissionState.request === 'inProgress' ? submissionState.action : undefined;
        const disabled = !!inProgressAction;

        switch (existingException?.status) {
            case undefined:
                return (
                    <Button disabled={disabled} onClick={createException} fontAwesomeImage={faSave} variant="primary" isLoading={inProgressAction === 'save'} loadingText="Saving...">
                        SAVE AS DRAFT
                    </Button>
                );
            case ExceptionStatus.DRAFT_OPEN:
                return (
                    <>
                        <div className={styles.deleteButton}>
                            <Button disabled={disabled} fontAwesomeImage={faTrash} onClick={() => setShowDeleteConfirmationModal(true)} variant="danger" isLoading={inProgressAction === 'delete'} loadingText="Deleting...">
                                DELETE
                            </Button>
                        </div>
                        <Button disabled={disabled} onClick={() => updateException(ExceptionStatus.DRAFT_OPEN, 'save')} fontAwesomeImage={faSave} variant="secondary" isLoading={inProgressAction === 'save'} loadingText="Saving...">
                            SAVE AS DRAFT
                        </Button>
                        <Button disabled={disabled} onClick={() => updateException(ExceptionStatus.APPROVED, 'approve')} fontAwesomeImage={faCheck} variant="primary" isLoading={inProgressAction === 'approve'} loadingText="Saving...">
                            SAVE AND APPROVE
                        </Button>
                    </>
                );
            case ExceptionStatus.APPROVED:
                const targetStatus = needsClosureStatement ? ExceptionStatus.DRAFT_CLOSE : ExceptionStatus.APPROVED;
                return (
                    <Button disabled={disabled} onClick={() => updateException(targetStatus, 'save')} fontAwesomeImage={faChevronCircleRight} variant="primary" isLoading={inProgressAction === 'save'} loadingText="Saving...">
                        SAVE
                    </Button>
                );
            case ExceptionStatus.DRAFT_CLOSE:
                return (
                    <>
                        <Button disabled={disabled} onClick={() => updateException(ExceptionStatus.DRAFT_CLOSE, 'save')} fontAwesomeImage={faSave} variant="secondary" isLoading={inProgressAction === 'save'} loadingText="Saving...">
                            SAVE
                        </Button>
                        <Button disabled={disabled} onClick={() => updateException(ExceptionStatus.CLOSED, 'approve')} fontAwesomeImage={faCheck} variant="primary" isLoading={inProgressAction === 'approve'} loadingText="Saving...">
                            SAVE AND CLOSE EXCEPTION
                        </Button>
                    </>
                );
            case ExceptionStatus.CLOSED:
                return <></>;
        }
    })();

    const fileDragAndDropProps: FileDragAndDropProps = {
        labelText: 'Files',
        onAddFiles: onAddFiles,
        onRemoveFile: onRemoveFile,
        files: files,
    };

    const deleteFile = (file: UploadedFile) => {
        const existingFilesCopy: UploadedFile[] = [...existingFiles];
        existingFilesCopy.splice(existingFiles.indexOf(file), 1);
        setExistingFiles(existingFilesCopy);
        setExistingFilesToDelete([...existingFilesToDelete, file.file_id]);
    };

    const handleTextChange = (setState: (value: string) => void) => (event: React.FormEvent<HTMLInputElement>) => setState(event.currentTarget.value);

    return (
        <>
            {deleteConfirmationModal}
            {toast}
            <Page
                headerBreadcrumb={
                    <Breadcrumb textColor="blue">
                        <BreadcrumbLink link={exceptionType === IssuesExceptionsModule.CONTROLS ? `/${ISSUES_EXCEPTIONS}#exceptions` : `/${TPRM}/${ISSUES_EXCEPTIONS}#exceptions`}>Exceptions</BreadcrumbLink>
                        {existingException !== undefined && !exceptionWasDeleted && <BreadcrumbLink link={getExceptionDetailsUrl(existingException.id, existingException.type)}>{submissionState.request === 'success' && submissionState.completedAction.type === 'updated' ? submissionState.completedAction.newException.title : existingException.title}</BreadcrumbLink>}
                        {existingException !== undefined && exceptionWasDeleted && <BreadcrumbText>{existingException.title}</BreadcrumbText>}
                        <BreadcrumbText>{headerText}</BreadcrumbText>
                    </Breadcrumb>
                }
                headerTitle={headerText}
                body={[
                    {
                        content: (
                            <Form>
                                <fieldset disabled={submissionState.request === 'success'}>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldText handleChange={handleTextChange(setTitle)} value={title || ''} formFieldId="title" formFieldLabel="Title" required />
                                        </div>
                                        {(props.type === 'creatingControlException' || props.type === 'editingControlException') && (
                                            <div className={styles.formFieldContainer}>
                                                <FormFieldText value={reference || ''} handleChange={handleTextChange(setReference)} formFieldId="reference" formFieldLabel="Reference" />
                                            </div>
                                        )}
                                    </div>
                                    {(props.type === 'creatingThirdPartyException' || props.type === 'editingThirdPartyException') && (
                                        <div className={styles.formFieldGroup}>
                                            <div className={styles.formFieldContainer}>
                                                <FormFieldSelect selectedOption={impactedThirdParty} disabled={props.type === 'editingThirdPartyException'} formFieldId="thirdParty" isRequiredField formFieldLabel="Third Party Impacted" handleChange={(value: ChangeEventType) => setImpactedThirdParty(value as string)} options={impactedThirdPartyOptions} />
                                            </div>
                                            <div className={styles.formFieldContainer}>
                                                <FormFieldText value={reference || ''} handleChange={handleTextChange(setReference)} formFieldId="reference" formFieldLabel="Reference" tooltip={ISSUES_EXCEPTIONS_REFERENCE} />
                                            </div>
                                        </div>
                                    )}
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldUserSelect selectedUser={owner} formFieldId="owner" formFieldLabel="Owner" onUserSelected={(user: UserResponse) => setOwner(user)} isRequiredField users={cachedData.users} />
                                        </div>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldUserSelect selectedUser={reviewer} formFieldId="reviewer" formFieldLabel="Reviewer" onUserSelected={(user: UserResponse) => setReviewer(user)} isRequiredField users={cachedData.users} />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldUserMultiSelect selectedUsers={delegates} formFieldId="delegates" formFieldLabel="Delegates" onUsersSelected={(users) => setDelegates(users ?? [])} />
                                        </div>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldDatePicker selected={expirationDateAsDate} handleChange={setExpirationDateAsDate} required dateFormat="MM/dd/yyyy" formFieldId="expirationDateAsDate" formFieldLabel="Expiration Date" placeholder={'MM/DD/YYYY'} />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldSelect selectedOption={likelihood} formFieldId="likelihood" isRequiredField formFieldLabel="Likelihood" handleChange={(value: ChangeEventType) => setLikelihood(value as ExceptionLikelihood)} options={ExceptionLikelihoodOptions} />
                                        </div>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldSelect selectedOption={impact} formFieldId="impact" isRequiredField formFieldLabel="Impact" handleChange={(value: ChangeEventType) => setImpact(value as ExceptionImpact)} options={ExceptionImpactOptions} />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldTextArea handleChange={handleTextChange(setCompensatingControls)} value={compensatingControls} formFieldId="compensatingControls" formFieldLabel="Compensating Controls" rows={2} tooltip={EXCEPTIONS_COMPENSATING_CONTROLS} />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldTextArea handleChange={handleTextChange(setDescription)} value={description} formFieldId="description" formFieldLabel="Description" rows={2} required />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldTextArea handleChange={handleTextChange(setRemediationPlan)} value={remediationPlan} formFieldId="remediationPlan" formFieldLabel="Remediation Plan" rows={2} required />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FormFieldTextArea handleChange={handleTextChange(setRiskAssessment)} value={riskAssessment} formFieldId="riskAssessment" required formFieldLabel="Risk Assessment" rows={2} />
                                        </div>
                                    </div>
                                    <div className={styles.formFieldGroup}>
                                        <div className={styles.formFieldContainer}>
                                            <FileDragAndDrop {...fileDragAndDropProps} />
                                            {existingException && existingException.files.length > 0 && (
                                                <>
                                                    <VisualLabel>Existing Files</VisualLabel>
                                                    <Table>
                                                        <TableBody>
                                                            {[...existingFiles]
                                                                .sort((fileA, fileB) => fileA.filename.localeCompare(fileB.filename))
                                                                .map((file) => (
                                                                    <TableRow key={file.file_id}>
                                                                        <TableCell>
                                                                            <Text noStyles>{file.filename}</Text>
                                                                        </TableCell>
                                                                        <TableOverflowCell>
                                                                            <div className={styles.overflowContainer}>
                                                                                <OverflowMenu
                                                                                    overflowItems={[
                                                                                        {
                                                                                            text: 'Download file',
                                                                                            onClickAction: () => downloadDocument(props.documentApi, file),
                                                                                            icon: faDownload,
                                                                                        },
                                                                                        {
                                                                                            text: 'Delete file',
                                                                                            onClickAction: () => deleteFile(file),
                                                                                            icon: faTrash,
                                                                                        },
                                                                                    ]}
                                                                                    accessibilityTitle={file.filename}
                                                                                />
                                                                            </div>
                                                                        </TableOverflowCell>
                                                                    </TableRow>
                                                                ))}
                                                        </TableBody>
                                                    </Table>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    {(props.type === 'creatingControlException' || props.type === 'editingControlException') && (
                                        <div className={styles.formFieldGroup}>
                                            <div className={styles.formFieldContainer}>
                                                <Text variant="Header2">Controls Impacted</Text>
                                                <MultipleControlMapping controls={props.controls} handleControlChange={setImpactedControls} currentMappedControlIdentifiers={impactedControls} />
                                            </div>
                                        </div>
                                    )}
                                    {needsClosureStatement && (
                                        <div className={styles.formFieldGroup}>
                                            <div className={styles.formFieldContainer}>
                                                <div className={styles.propertyGroup}>
                                                    <div className={styles.propertyContainer}>
                                                        <FormFieldTextArea required value={closureStatement} handleChange={handleTextChange(setClosureStatement)} formFieldId="closureStatement" formFieldLabel="Closure Statement" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className={styles.buttonContainer}>{buttons}</div>
                                </fieldset>
                            </Form>
                        ),
                    },
                ]}
            />
        </>
    );
};
