import { faSave } from '@fortawesome/free-solid-svg-icons';
import { produce } from 'immer';
import { isUndefined } from 'lodash-es';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { ProgressBarIndicatorProps } from 'Components/Indicator/ProgressBarIndicator';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { controlTextToString } from 'Helpers/ControlFormatter/ControlFormatter';
import { jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';
import { submitRequestWithFiles } from 'Helpers/FileUtils';
import { useFileDragAndDrop } from 'Hooks/FileDragAndDrop';
import { UploadedFile } from 'Models/Files';
import { ControlEffectivenessProgressBarVariantAndPercentage, ControlText, Effectiveness, EffectivenessSelectOptions, numberAsEffectiveness, numberAsEffectivenessString } from 'Models/OperationalControls';
import { ScheduleFrequencyKeys } from 'Models/ScheduleFrequency';
import { Answer, MultipleSelectQuestionInstance, QuestionType, QuestionnaireReport, RiskRatingAsString, RiskRatingSelectOptions, Service, ServiceAssessmentState, SingleSelectQuestionInstance, UpdateServiceAssessmentRequest } from 'Models/TPRM';
import { ALLOW_ALL_ACTIONS, DocumentListing, DocumentListingProps } from 'Pages/TPRM/Components/DocumentListing/DocumentListing';
import { DeleteServiceAssessmentDocumentModal, DeleteServiceAssessmentDocumentModalProps } from 'Pages/TPRM/DueDiligenceQuestionnaire/DeleteDocumentationModal/DeleteServiceAssessmentDocumentModal';

import styles from './AssessmentAndReportTab.module.css';
import { ControlAssessmentAccordionControl, ControlAssessmentAccordionFramework, ControlAssessmentAccordionGroup, ControlAssessmentAccordionQuestion } from './ControlAssessmentAccordion/ControlAssessmentAccordion';
import { SubmitThirdPartyServiceAssessmentConfirmationModal, SubmitThirdPartyServiceAssessmentConfirmationModalProps } from './SubmitThirdPartyServiceAssessmentConfirmationModal/SubmitThirdPartyServiceAssessmentConfirmationModal';

export enum Modals {
    ConfirmationModal,
    None,
}

interface UIState {
    thirdPartyId: string;
    serviceId: string;
    thirdPartyServiceTitle: string;
    effectivenessProgressBar: ProgressBarIndicatorProps;
    averageControlEffectiveness: string;
    report: Map<string, Framework>;
    inherentRisk: string;
    isArchiving: boolean;
    isNotStarted: boolean;
    isNotInProgress: boolean;
}

export interface Framework {
    framework: string;
    frameworkName: string;
    frameworkVersion: string;
    groups: Map<string, Group>;
    effectiveness: Effectiveness;
}

export interface Group {
    framework: string;
    groupId: string;
    groupName: string;
    groupDescription?: string;
    controls: Map<string, Control>;
    displayText: string;
    effectiveness: Effectiveness;
}

export interface Control {
    framework: string;
    groupId: string;
    controlId: string;
    controlText: ControlText[];
    controlName?: string;
    questions: Map<number, Question>;
    displayText: string;
    effectiveness: Effectiveness;
}
export interface Question {
    _type: QuestionType;
    text: string;
    options?: string[];
    answerText?: Answer;
    answerIndex?: number;
    answerIndexes?: number[];
    answerDocuments: UploadedFile[];
}

interface FormFieldsState {
    additionalInformation?: string;
    overallEffectiveness?: number;
    residualRisk?: number;
    [index: string]: string | number | undefined;
}

interface FormState {
    isSaving: boolean;
    isSubmitting: boolean;
    successMessage?: string;
    failureMessage?: string;
}

export interface AssessmentAndReportTabProps {
    tprmApi: TPRMApi;
    documentApi: DocumentApi;
    serviceResponse: Service;
    questionnaireReport: QuestionnaireReport;
    onSaved: () => void;
    onDocumentDeleted: () => void;
}

const calculateRelativeDueDate = (scheduleNumber: string, scheduleFrequency: ScheduleFrequencyKeys): Date => {
    let momentKey: 'days' | 'months' | 'years';
    switch (scheduleFrequency) {
        case 'DAYS':
            momentKey = 'days';
            break;
        case 'MONTHS':
            momentKey = 'months';
            break;
        case 'YEARS':
            momentKey = 'years';
            break;
    }

    return moment().add(scheduleNumber, momentKey).toDate();
};

export const AssessmentAndReportTab = (props: AssessmentAndReportTabProps): JSX.Element => {
    const [uiState, setUiState] = useState<UIState>();
    const [formFieldState, setFormFieldState] = useState<FormFieldsState>();
    const [formState, setFormState] = useState<FormState>({ isSaving: false, isSubmitting: false });
    const [nextAssessmentDate, setNextAssessmentDate] = useState<Date | undefined>(props.serviceResponse.assessment_schedule ? calculateRelativeDueDate(props.serviceResponse.assessment_schedule.schedule_number.toString(), props.serviceResponse.assessment_schedule.schedule_frequency) : undefined);
    const [documentToDelete, setDocumentToDelete] = useState<UploadedFile>();
    const [selectedDocuments, onSelectNewDocuments, onDeselectNewDocument, clearDocuments] = useFileDragAndDrop();
    const [displayedModal, setDisplayedModal] = useState<Modals>(Modals.None);

    useEffect(() => {
        const formState: FormFieldsState = {
            additionalInformation: props.serviceResponse.assessment_additional_information ? props.serviceResponse.assessment_additional_information : '',
            overallEffectiveness: props.serviceResponse.assessment_control_effectiveness ? props.serviceResponse.assessment_control_effectiveness : 0,
            residualRisk: props.serviceResponse.assessment_residual_risk_score ? props.serviceResponse.assessment_residual_risk_score : undefined,
        };
        setFormFieldState(formState);

        const report = new Map(
            props.questionnaireReport.control_frameworks.map((frameworkResponse) => {
                const groups = new Map(
                    frameworkResponse.control_groups.map((groupResponse) => {
                        const controls = new Map(
                            groupResponse.controls.map((controlResponse) => {
                                const controlDisplayText = controlResponse.is_custom ? controlResponse.control_name! : `${controlResponse.control_id}. ${controlTextToString(controlResponse.control_text)}`;
                                const questions = new Map(
                                    controlResponse.questions.map((questionResponse, index) => {
                                        const question: Question = {
                                            _type: questionResponse._type,
                                            text: questionResponse.text,
                                            answerText: questionResponse.answer_text,
                                            answerDocuments: questionResponse.answer_documents,
                                        };
                                        if (question._type === QuestionType.MULTIPLE_SELECT) {
                                            question.answerIndexes = (questionResponse as MultipleSelectQuestionInstance).answer_indexes;
                                            question.options = (questionResponse as MultipleSelectQuestionInstance).options;
                                        } else if (question._type === QuestionType.SINGLE_SELECT) {
                                            question.answerIndex = (questionResponse as SingleSelectQuestionInstance).answer_index;
                                            question.options = (questionResponse as SingleSelectQuestionInstance).options;
                                        }
                                        return [index, question];
                                    })
                                );
                                const control: Control = {
                                    framework: controlResponse.control_framework,
                                    groupId: controlResponse.control_group_id,
                                    controlId: controlResponse.control_id,
                                    controlText: controlResponse.control_text,
                                    controlName: controlResponse.control_name,
                                    questions: questions,
                                    effectiveness: controlResponse.control_assessment_effectiveness ?? Effectiveness.INACTIVE,
                                    displayText: controlDisplayText,
                                };

                                return [controlResponse.control_id, control];
                            })
                        );
                        const groupDisplayText = groupResponse.is_custom ? groupResponse.control_group_name : `${groupResponse.control_group_id}. ${groupResponse.control_group_name}`;
                        const group: Group = {
                            framework: groupResponse.control_framework,
                            groupId: groupResponse.control_group_id,
                            groupName: groupResponse.control_group_name,
                            groupDescription: groupResponse.control_group_description,
                            controls: controls,
                            displayText: groupDisplayText,
                            effectiveness: groupResponse.control_group_effectiveness,
                        };

                        return [groupResponse.control_group_id, group];
                    })
                );

                const framework: Framework = {
                    framework: frameworkResponse.control_framework,
                    frameworkName: frameworkResponse.control_framework_name,
                    frameworkVersion: frameworkResponse.control_framework_version,
                    groups: groups,
                    effectiveness: frameworkResponse.control_framework_effectiveness,
                };

                return [frameworkResponse.control_framework, framework];
            })
        );

        const uiState: UIState = {
            thirdPartyId: props.serviceResponse.vendor_id,
            serviceId: props.serviceResponse.id,
            thirdPartyServiceTitle: `${props.serviceResponse.vendor_name} - ${props.serviceResponse.name}`,
            effectivenessProgressBar: {
                ...ControlEffectivenessProgressBarVariantAndPercentage(numberAsEffectiveness(props.questionnaireReport.average_control_effectiveness)),
                size: 'large',
            },
            averageControlEffectiveness: numberAsEffectivenessString(props.questionnaireReport.average_control_effectiveness),
            report: report,
            inherentRisk: RiskRatingAsString(props.serviceResponse.inherent_risk_score),
            isArchiving: props.serviceResponse.assessment_state === ServiceAssessmentState.ARCHIVING,
            isNotStarted: props.serviceResponse.assessment_state === ServiceAssessmentState.NOT_STARTED,
            isNotInProgress: props.serviceResponse.assessment_state !== ServiceAssessmentState.IN_PROGRESS,
        };
        setUiState(uiState);
    }, [props.serviceResponse, props.questionnaireReport]);

    const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
        event.preventDefault();

        setFormFieldState({ ...formFieldState, [event.currentTarget.name]: event.currentTarget.value });
    };

    const handleSelectChange = (value: ChangeEventType, formFieldId: string): void => {
        setFormFieldState({ ...formFieldState, [formFieldId]: value as number });
    };

    const validateAndDisplayConfirmationModal = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!formFieldState?.residualRisk) {
            setFormState({ isSaving: false, isSubmitting: false, failureMessage: 'Residual risk is required.' });
            return;
        } else {
            setFormState({ isSaving: false, isSubmitting: false, failureMessage: undefined });
        }

        setDisplayedModal(Modals.ConfirmationModal);
    };

    const submitAssessment = () => {
        setFormState({ isSaving: false, isSubmitting: true });
        setDisplayedModal(Modals.None);
        updateAssessment(true);
    };

    const handleSave = () => {
        setFormState({ isSaving: true, isSubmitting: false });
        updateAssessment(false);
    };

    const updateAssessment = async (shouldArchive: boolean) => {
        try {
            await submitRequestWithFiles(
                props.documentApi,
                selectedDocuments.map((document) => ({ file: document })),
                async (newDocumentation) => {
                    const modifyServiceRequest: UpdateServiceAssessmentRequest = {
                        assessment_additional_information: formFieldState?.additionalInformation !== props.serviceResponse?.assessment_additional_information ? formFieldState?.additionalInformation : undefined,
                        assessment_control_effectiveness: formFieldState?.overallEffectiveness !== props.serviceResponse?.assessment_control_effectiveness ? formFieldState?.overallEffectiveness : undefined,
                        assessment_residual_risk_score: formFieldState?.residualRisk !== props.serviceResponse?.assessment_residual_risk_score ? formFieldState?.residualRisk : undefined,
                        new_assessment_documents: newDocumentation.length > 0 ? newDocumentation : undefined,
                        is_submit: shouldArchive,
                        new_assessment_due_date: !isUndefined(nextAssessmentDate) && shouldArchive ? jsDateToIso8601(nextAssessmentDate) : undefined,
                    };

                    await props.tprmApi.modifyServiceAssessment(modifyServiceRequest, props.serviceResponse.vendor_id, props.serviceResponse.id);
                }
            );

            let successMessage = 'Assessment updated.';

            if (shouldArchive) {
                successMessage = `${successMessage} The risk workflow is being archived. You cannot make any changes while the risk workflow is being archived.`;
                const newUIState = produce(uiState, (draft: UIState) => {
                    draft.isArchiving = true;
                });
                setUiState(newUIState);
            }

            setFormState({ isSaving: false, isSubmitting: false, successMessage: successMessage });
            clearDocuments();
            props.onSaved();
        } catch (error) {
            setFormState({ isSaving: false, isSubmitting: false, failureMessage: error.message });
        }
    };

    if (formFieldState && uiState) {
        const documentListingProps: DocumentListingProps = {
            documentApi: props.documentApi,
            dragAndDropInputId: 'service-assessment-files',
            dragAndDropLabelText: 'SUPPORTING DOCUMENTATION',
            existingDocuments: props.serviceResponse.assessment_documents,
            newDocuments: selectedDocuments,
            actions: uiState.isNotInProgress ? ['download'] : ALLOW_ALL_ACTIONS,
            onSelectNewDocuments: onSelectNewDocuments,
            onDeselectNewDocument: onDeselectNewDocument,
            onSelectExistingDocumentToDelete: (document) => setDocumentToDelete(document),
        };

        const modalProps: DeleteServiceAssessmentDocumentModalProps | undefined = documentToDelete
            ? {
                  tprmApi: props.tprmApi,
                  service: props.serviceResponse,
                  document: documentToDelete,
                  hideModal: () => setDocumentToDelete(undefined),
                  onDocumentDeleted: props.onDocumentDeleted,
              }
            : undefined;

        const confirmationModalProps: SubmitThirdPartyServiceAssessmentConfirmationModalProps = {
            hideModal: () => setDisplayedModal(Modals.None),
            onServiceAssessmentDueDateChanged: (dueDate?: Date) => setNextAssessmentDate(dueDate),
            serviceAssessmentDueDate: nextAssessmentDate,
            onConfirm: () => submitAssessment(),
            service: props.serviceResponse,
        };

        return (
            <>
                <PageCell>
                    {modalProps && <DeleteServiceAssessmentDocumentModal {...modalProps} />}
                    {displayedModal === Modals.ConfirmationModal && <SubmitThirdPartyServiceAssessmentConfirmationModal {...confirmationModalProps} />}
                    {formState.successMessage && <Alert variant="success">{formState.successMessage}</Alert>}
                    {formState.failureMessage && <Alert variant="danger">{formState.failureMessage}</Alert>}
                    {uiState.isArchiving && formState.successMessage === undefined && <Alert variant="warning">This assessment is being archived and changes cannot be made.</Alert>}
                    {uiState.isNotStarted && <Alert variant="warning">To make changes to this report, begin the Risk Workflow from the Service Dashboard.</Alert>}
                    <Text variant="Header2">Service Assessment</Text>
                    <hr />
                    <Text variant="Header3">This assessment will assign the following chosen effectiveness rating to the service.</Text>
                    <Form onSubmit={validateAndDisplayConfirmationModal}>
                        <div className={styles.margin10}>
                            <DocumentListing {...documentListingProps} />
                        </div>
                        <div className={styles.margin10}>
                            <FormFieldTextArea disabled={uiState.isNotInProgress} formFieldId="additionalInformation" handleChange={handleChange} formFieldLabel="Additional Information" value={formFieldState.additionalInformation} />
                        </div>
                        <div className={styles.margin10}>
                            <FormFieldSelect disabled={uiState.isNotInProgress} options={EffectivenessSelectOptions} handleChange={handleSelectChange} formFieldId={'overallEffectiveness'} selectedOption={formFieldState.overallEffectiveness} formFieldLabel={`Overall Control Effectiveness${props.serviceResponse.common_assessment_parent ? ' (from common assessment)' : ''}`} />
                        </div>
                        <div className={styles.margin10}>
                            <FormFieldSelect disabled={uiState.isNotInProgress} options={RiskRatingSelectOptions} handleChange={handleSelectChange} formFieldId={'residualRisk'} selectedOption={formFieldState.residualRisk} formFieldLabel="Residual Risk" />
                        </div>
                        <div className={styles.buttons}>
                            <Button variant="primary" onClick={handleSave} fontAwesomeImage={faSave} disabled={formState.isSaving || formState.isSubmitting || uiState.isNotInProgress} isLoading={formState.isSaving} loadingText="Saving...">
                                SAVE
                            </Button>
                            <Button variant="submit" disabled={formState.isSaving || formState.isSubmitting || uiState.isNotInProgress} isLoading={formState.isSubmitting} loadingText="Submitting...">
                                SUBMIT
                            </Button>
                        </div>
                    </Form>
                </PageCell>
                <div className={styles.marginTop10}>
                    <PageCell>
                        <Text variant="Header2">{`Control Assessment${props.serviceResponse.common_assessment_parent ? ' (from common assessment)' : ''}`}</Text>
                        <hr />
                        <div className={styles.accordionHeaderContainer}>
                            <Text variant="Text3" noStyles>
                                Framework/Control
                            </Text>
                            <div className={styles.accordionRightContent}>
                                <Text variant="Text3" noStyles>
                                    Effectiveness
                                </Text>
                            </div>
                        </div>
                        {Array.from(uiState.report.values()).map((framework: Framework, index: number) => (
                            <ControlAssessmentAccordionFramework key={index} framework={framework}>
                                {Array.from(framework.groups.values()).map((group: Group, index: number) => (
                                    <ControlAssessmentAccordionGroup key={index} group={group}>
                                        {Array.from(group.controls.values()).map((control: Control, index: number) => (
                                            <ControlAssessmentAccordionControl key={index} control={control}>
                                                {Array.from(control.questions.values()).map((question: Question, index: number) => (
                                                    <ControlAssessmentAccordionQuestion key={index} documentApi={props.documentApi} question={question} questionNumber={index} />
                                                ))}
                                            </ControlAssessmentAccordionControl>
                                        ))}
                                    </ControlAssessmentAccordionGroup>
                                ))}
                            </ControlAssessmentAccordionFramework>
                        ))}
                    </PageCell>
                </div>
            </>
        );
    } else {
        return <Placeholder />;
    }
};
