import { capitalize } from 'lodash-es';
import { useEffect, useState } from 'react';

import { ExportsApi } from 'Api/Exports/ExportsApi';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { DataGrid, GridColumn, dateColumn } from 'Components/DataGrid/DataGrid';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Text } from 'Components/Text/Text';
import { EXPORTS } from 'Config/Paths';
import { controlComparator } from 'Helpers/Compare';
import { getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { UserNameFormat, getDepartmentFromSubject, getUserNameFromSubject } from 'Helpers/UserUtils';
import { IssueExportRow } from 'Models/Exports';
import { OperationalControl } from 'Models/OperationalControls';

import styles from '../DataExports.module.css';

export interface IssuesExportProps {
    exportsApi: ExportsApi;
    disableVirtualizationForDataGrid?: boolean;
}

export const IssuesExport = (props: IssuesExportProps): JSX.Element => {
    const [issues, setIssues] = useState<IssueExportRow[]>();
    const [error, setError] = useState<string>();
    const cachedData = useCachedData();

    useEffect(() => {
        const getIssues = async (): Promise<void> => {
            try {
                const issuesResponse = await props.exportsApi.getIssuesExport();
                setIssues(issuesResponse.data);
            } catch (error) {
                setError(error.message);
            }
        };

        getIssues();
    }, [props.exportsApi]);

    const columns: GridColumn<IssueExportRow>[] = [
        { field: 'title', headerName: 'Issue Title', width: 300 },
        { field: 'owner', headerName: 'Owner', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'owner_department', headerName: 'Owner Department', width: 300, valueGetter: (_value, row) => getDepartmentFromSubject(row.owner, cachedData.users) },
        {
            field: 'delegates',
            headerName: 'Delegates',
            width: 300,
            valueGetter: (value) =>
                value
                    .map((userId: string) => getUserNameFromSubject(userId, cachedData.users, UserNameFormat.FIRST_SPACE_LAST))
                    .sort()
                    .join(', '),
        },
        { field: 'status', headerName: 'Status', width: 300, valueGetter: (value) => capitalize(value) },
        { field: 'priority', headerName: 'Priority', width: 300, valueGetter: (value) => capitalize(value) },
        { field: 'source', headerName: 'Source', width: 300 },
        { field: 'reference', headerName: 'Reference', width: 300 },
        { field: 'description', headerName: 'Description', width: 300 },
        { field: 'risk_assessment', headerName: 'Risk Assessment', width: 300 },
        { field: 'remediation_plan', headerName: 'Remediation Plan', width: 300 },
        {
            field: 'impacted_controls',
            headerName: 'Impacted Controls',
            width: 300,
            valueGetter: (_value, row) =>
                row.impacted_controls
                    .sort(controlComparator)
                    .map((control: OperationalControl) => getHumanReadableControlIdFromControl(control))
                    .join(', '),
        },
        { field: 'impacted_third_party', headerName: 'Impacted Third Party', width: 300 },
        dateColumn('created_timestamp', 'Open Date'),
        { field: 'created_by', headerName: 'Created By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        dateColumn('last_updated_timestamp', 'Last Updated'),
        { field: 'last_updated_by', headerName: 'Last Updated By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        dateColumn('due_date', 'Due Date'),
        dateColumn('closed_timestamp', 'Closure Date'),
        { field: 'closed_by', headerName: 'Closed By', width: 300, valueGetter: (value) => getUserNameFromSubject(value, cachedData.users, UserNameFormat.FIRST_SPACE_LAST) },
        { field: 'closure_statement', headerName: 'Closure Statement', width: 300 },
        {
            field: 'file_names',
            headerName: 'File Names',
            width: 300,
            valueGetter: (value) => value.sort().join(', '),
        },
        { field: 'id', headerName: 'ID', width: 300 },
    ];

    if (issues) {
        return (
            <>
                <PageBackground color="grey">
                    <PageContent>
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${EXPORTS}`}>Exports</BreadcrumbLink>
                            <BreadcrumbText>Issues</BreadcrumbText>
                        </Breadcrumb>
                        <div className={styles.headerContainer}>
                            <Text variant="Header1" color="darkBlue">
                                Issues Export
                            </Text>
                        </div>
                    </PageContent>
                </PageBackground>
                <PageBackground color="white">
                    <PageContent>
                        <DataGrid columns={columns} getRowId={(issue: IssueExportRow) => issue.id} rows={issues} title="Issues Export Preview" fileName="Issues" disableVirtualization={props.disableVirtualizationForDataGrid} />
                    </PageContent>
                </PageBackground>
            </>
        );
    } else if (error) {
        return <Text color="darkBlue">{error}</Text>;
    } else return <Placeholder />;
};
