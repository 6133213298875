import { useContext } from 'react';

import { Button, Link } from 'Components/Buttons/Buttons';
import { CachedDataContext, clientHasAuthorizedLicense } from 'Components/Context/CachedDataContext';
import { HorseshoeProgressIndicator } from 'Components/Indicator/HorseshoeProgressIndicator';
import { Step, StepLabel, Stepper } from 'Components/Stepper/Stepper';
import { Text } from 'Components/Text/Text';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { ASSESSMENTS, DASHBOARDS, IRQ, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { License } from 'Models/ClientDetailsModel';
import { Service, ServiceAssessmentState } from 'Models/TPRM';

import styles from '../ServiceDashboard.module.css';

export interface RiskWorkflowProps {
    assessmentDateStarted?: string;
    assessmentStatus: string;
    assessmentStatusLabel?: string;
    assessmentPercentComplete: number;
    controlAssessmentHasControls: boolean;
    ddqDateStarted?: string;
    ddqPath: string;
    ddqPercentComplete: number;
    ddqStatus: string;
    ddqStatusLabel?: string;
    displayAdvertiseAiTprmServiceAssessmentModal: () => void;
    displayConfirmAiTprmServiceAssessmentModal: () => void;
    displayConfirmationEmailModal: () => void;
    hasParent: boolean;
    irqDateCompleted?: string;
    irqPercentComplete: number;
    irqStatus: string;
    reportDateCompleted?: string;
    reportPercentComplete: number;
    reportStatus: string;
    riskWorkflowStep: number;
    serviceId: string;
    serviceResponse: Service;
    thirdPartyId: string;
    thirdPartyQuestionnaireHasQuestions: boolean;
}

export const RiskWorkflow = (props: RiskWorkflowProps): JSX.Element => {
    const cachedDataContext = useContext(CachedDataContext);

    if (props.serviceResponse.assessment_state === ServiceAssessmentState.NOT_STARTED) {
        return (
            <>
                <Link size="sm" to={`/${TPRM}/${THIRD_PARTIES}/${props.thirdPartyId}/${SERVICES}/${props.serviceId}/${IRQ}`}>
                    Begin Assessment
                </Link>
                <Link size="sm" to={props.ddqPath}>
                    View Questionnaire
                </Link>
                <Link size="sm" to={`/${TPRM}/${THIRD_PARTIES}/${props.thirdPartyId}/${SERVICES}/${props.serviceId}/${ASSESSMENTS}#reports`}>
                    View Past Assessments
                </Link>
            </>
        );
    } else if (props.serviceResponse.assessment_state === ServiceAssessmentState.ARCHIVING) {
        return (
            <Text color="white" variant="Text2">
                The Risk Workflow can be started after the service completes archiving.
            </Text>
        );
    } else {
        return (
            <>
                <div>
                    <Stepper activeStep={props.riskWorkflowStep}>
                        <Step>
                            <StepLabel>
                                <div className={styles.stepLabelLinkButton}>
                                    <Link size="lg" to={`/${TPRM}/${THIRD_PARTIES}/${props.thirdPartyId}/${SERVICES}/${props.serviceId}/${IRQ}`}>
                                        Inherent Risk Questionnaire
                                    </Link>
                                </div>
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>
                                <div className={styles.stepLabelText}>
                                    <Text color="white" noStyles variant="Text2">
                                        Third-Party Questionnaire
                                    </Text>
                                </div>
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>
                                {props.controlAssessmentHasControls ? (
                                    <div className={styles.stepLabelLinkButton}>
                                        <Link size="lg" to={props.ddqPath} disabled>
                                            Control Assessment
                                        </Link>
                                    </div>
                                ) : (
                                    <div className={styles.stepLabelText}>
                                        <Text color="white" noStyles variant="Text2">
                                            Control Assessment
                                        </Text>
                                    </div>
                                )}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel>
                                <div className={styles.stepLabelLinkButton}>
                                    <Link size="lg" to={`/${TPRM}/${THIRD_PARTIES}/${props.thirdPartyId}/${SERVICES}/${props.serviceId}/${ASSESSMENTS}`}>
                                        Final Review
                                    </Link>
                                </div>
                            </StepLabel>
                        </Step>
                    </Stepper>
                </div>
                <div className={styles.workflow}>
                    <div className={styles.workflowSection}>
                        <div className={styles.progressBarContainer}>
                            <HorseshoeProgressIndicator fill={props.irqPercentComplete}>
                                <Text variant="Text2" color="white">
                                    {props.irqStatus}
                                </Text>
                            </HorseshoeProgressIndicator>
                        </div>
                        {props.irqDateCompleted && (
                            <div className={styles.workflowSection}>
                                <Text variant="Header3" color="white">
                                    Date Completed
                                </Text>
                                <Text variant="Text2" color="white">
                                    {props.irqDateCompleted}
                                </Text>
                            </div>
                        )}
                    </div>
                    <div className={styles.verticalLine}></div>
                    <div className={styles.workflowSection}>
                        <div className={`${styles.progressBarContainer}`}>
                            <HorseshoeProgressIndicator fill={props.ddqPercentComplete}>
                                <div className={styles.questionStatus}>
                                    <Text variant="Text2" color="white" noStyles>
                                        {props.ddqStatus}
                                    </Text>
                                    {!props.thirdPartyQuestionnaireHasQuestions && <FormFieldTooltip text="The third-party questionnaire does not have any questions configured for the selected risk rating." />}
                                </div>
                                {props.ddqStatusLabel && (
                                    <div className={styles.statusLabel}>
                                        <Text variant="Text3" color="white" noStyles>
                                            {props.ddqStatusLabel}
                                        </Text>
                                    </div>
                                )}
                            </HorseshoeProgressIndicator>
                        </div>
                        {props.ddqDateStarted && (
                            <div className={styles.workflowSection}>
                                <Text variant="Header3" color="white">
                                    Date Started
                                </Text>
                                <Text variant="Text2" color="white">
                                    {props.ddqDateStarted}
                                </Text>
                            </div>
                        )}
                        {props.irqDateCompleted && (
                            <div className={styles.notifyVSCLinkButton}>
                                {props.hasParent && (
                                    <Link size="sm" to={{ pathname: `/${TPRM}/${THIRD_PARTIES}/${props.serviceResponse?.common_assessment_parent?.vendor_id}/${SERVICES}/${props.serviceResponse?.common_assessment_parent?.id}/${DASHBOARDS}` }} state={{ showConfirmNotificationEmailModal: true }}>
                                        Notify Common Assessment Third-Party Service Contact
                                    </Link>
                                )}
                                {!props.hasParent && props.serviceResponse.vendor_contacts.some((thirdPartyContact) => thirdPartyContact.email_address) && (
                                    <Button variant="linkText" size="sm" onClick={props.displayConfirmationEmailModal}>
                                        Notify Third-Party Service Contact
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={styles.workflowSection}>
                        <div className={`${styles.progressBarContainer}`}>
                            <HorseshoeProgressIndicator fill={props.assessmentPercentComplete}>
                                <div className={styles.questionStatus}>
                                    <Text variant="Text2" color="white" noStyles>
                                        {props.assessmentStatus}
                                    </Text>
                                    {!props.controlAssessmentHasControls && <FormFieldTooltip text="The third-party questionnaire does not have any controls configured for the selected risk rating." />}
                                </div>
                                {props.assessmentStatusLabel && (
                                    <div className={styles.statusLabel}>
                                        <Text variant="Text3" color="white" noStyles>
                                            {props.assessmentStatusLabel}
                                        </Text>
                                    </div>
                                )}
                            </HorseshoeProgressIndicator>
                        </div>
                        {props.assessmentDateStarted && (
                            <div className={styles.workflowSection}>
                                <Text variant="Header3" color="white">
                                    Date Started
                                </Text>
                                <Text variant="Text2" color="white">
                                    {props.assessmentDateStarted}
                                </Text>
                            </div>
                        )}
                        {cachedDataContext && (
                            <Button variant="linkText" size="sm" onClick={clientHasAuthorizedLicense([License.ARTIFICIAL_INTELLIGENCE], cachedDataContext.clientDetails.licenses) ? props.displayConfirmAiTprmServiceAssessmentModal : props.displayAdvertiseAiTprmServiceAssessmentModal}>
                                SummIT Guide™
                            </Button>
                        )}
                    </div>
                    <div className={styles.verticalLine}></div>
                    <div className={styles.workflowSection}>
                        <div className={styles.progressBarContainer}>
                            <HorseshoeProgressIndicator fill={props.reportPercentComplete}>
                                <Text variant="Text2" color="white">
                                    {props.reportStatus}
                                </Text>
                            </HorseshoeProgressIndicator>
                        </div>
                        {props.reportDateCompleted && (
                            <div className={styles.workflowSection}>
                                <Text variant="Header3" color="white">
                                    Last Submitted
                                </Text>
                                <Text variant="Text2" color="white">
                                    {props.reportDateCompleted}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
};
