import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { DataGridPro, GridColDef, GridColType, GridCsvExportOptions, GridCsvGetRowsToExportParams, GridFilterInputValue, GridFilterItem, GridFilterOperator, GridSlotProps, GridToolbarContainer, getGridStringOperators, gridFilteredSortedRowIdsSelector, useGridApiContext } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';

import { PageCell } from 'Components/Containers/PageCell/PageCell';
import { ModalHeaderWithButton } from 'Components/Modal/ModalHeader';
import { iso8601ToJsDate, iso8601ToUsDateLong, jsDateToIso8601 } from 'Helpers/DateTimeUtils/DateTimeUtils';

import styles from './DataGrid.module.css';

declare module '@mui/x-data-grid-pro' {
    interface ToolbarPropsOverrides {
        title: string;
        fileName: string;
    }
}

export const INSECURE_CSV_START_CHARACTERS = ['=', '+', '-', '@', '\t', '\r'];

export type ValueGetterReturnType = string | number | undefined | Date;
export type ValueFormatterReturnType = string | number | undefined;

export interface GridColumn<T> {
    field: fieldKeys<T>;
    headerName: string;
    width?: number;
    valueGetter?: (value: any, row: any) => ValueGetterReturnType;
    type?: GridColType;
    valueFormatter?: (value: any, row: any) => ValueFormatterReturnType;
    headerAlign?: 'left' | 'right' | 'center';
}

type fieldKeys<T> = Extract<keyof T, string>;

export interface DataGridProps<T> {
    columns: GridColumn<T>[];
    disableVirtualization?: boolean;
    fileName: string;
    getRowId: (row: any) => string; // Function that creates a unique ID for each row. Reference: https://mui.com/x/react-data-grid/row-definition/#row-identifier
    rows: Readonly<
        {
            [key: string]: any;
        }[]
    >;
    title: string;
}

/**
 * Returns a GridColumn that formats ISO8601 string values into a standard date format.
 */
export const dateColumn = <T extends object>(field: fieldKeys<T>, headerName: string): GridColumn<T> => {
    return {
        type: 'date',
        field: field,
        headerName: headerName,
        width: 300,
        valueGetter: (value, row) => (value !== undefined ? iso8601ToJsDate(value) : undefined),
        valueFormatter: (value, row) => (value !== undefined ? iso8601ToUsDateLong(value) : undefined),
    };
};

const CustomToolbar = (props: GridSlotProps['toolbar'] & { title: string; fileName: string }) => {
    const apiRef = useGridApiContext();
    const handleExport = (options: GridCsvExportOptions) => apiRef.current.exportDataAsCsv(options);

    const todaysDate = jsDateToIso8601(new Date());
    return (
        <GridToolbarContainer sx={{ padding: '0px' }}>
            <ModalHeaderWithButton title={props.title} onClick={() => handleExport({ getRowsToExport: getRowsFromCurrentPage, fileName: `${props.fileName} - ${todaysDate}` })} buttonText="Export" fontAwesomeImage={faFileExport} />
        </GridToolbarContainer>
    );
};

const getRowsFromCurrentPage = ({ apiRef }: GridCsvGetRowsToExportParams) => gridFilteredSortedRowIdsSelector(apiRef);

const gridFilterOption = (): GridFilterOperator => {
    return {
        label: 'does not contain',
        value: 'does not contain',
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                return null;
            }

            return (params): boolean => {
                return params.value != null && !(params.value as string).toLowerCase().includes((filterItem.value as string).toLowerCase());
            };
        },
        InputComponent: GridFilterInputValue,
    };
};

/**
 * Removes insecure leading characters from any string that may be exported to CSV.
 * This, in combination with the fact that DataGrid already escapes quote and delimiter characters when exporting to CSV, mitigates CSV injection vulnerabilities.
 * For more information, see: https://owasp.org/www-community/attacks/CSV_Injection.
 *
 * @param value The string that may be exported as a "cell" in a CSV file.
 * @returns A new, sanitized string. The original string is not mutated.
 */
const sanitizeCsvValue = (value: string): string => {
    let newValue = value;

    while (newValue.length > 0 && INSECURE_CSV_START_CHARACTERS.includes(newValue.charAt(0))) {
        newValue = newValue.substring(1);
    }

    return newValue;
};

/**
 * This function is applied to all DataGrid columns, to sanitize all string values displayed in any instance of our DataGrid.
 * If a column has a `valueGetter` defined, sanitization occurs after that `valueGetter` is run.
 *
 * @param col The column to which sanitization logic will be applied.
 * @returns A `valueGetter` to be set for the column.
 */
const getSanitizingValueGetterForColumn = <T,>(col: GridColumn<T>) => {
    const valueGetter = (params: any, row: any): ValueGetterReturnType => {
        let value = col.valueGetter !== undefined ? col.valueGetter(params, row) : params;

        if (typeof value === 'string') {
            value = sanitizeCsvValue(value);
        }

        return value;
    };

    return valueGetter;
};

/**
 * This function is applied to all DataGrid columns, to sanitize all string values displayed in any instance of our DataGrid.
 * If a column has a `valueFormatter` defined, sanitization occurs after that `valueFormatter` is run.
 *
 * @param col The column to which sanitization logic will be applied.
 * @returns A `valueFormatter` to be set for the column.
 */
const getSanitizingValueFormatterForColumn = <T,>(col: GridColumn<T>) => {
    const valueFormatter = (params: any, row: any): ValueFormatterReturnType => {
        let value = col.valueFormatter !== undefined ? col.valueFormatter(params, row) : params;

        if (typeof value === 'string') {
            value = sanitizeCsvValue(value);
        }

        return value;
    };

    return valueFormatter;
};

export const DataGrid = <T extends object>(props: DataGridProps<T>): JSX.Element => {
    const initialState: GridInitialStatePro = {
        sorting: {
            sortModel: [{ field: props.columns[0].field, sort: props.columns[0].type === 'date' ? 'desc' : 'asc' }],
        },
    };

    const columns: GridColDef[] = props.columns.map((col) => {
        const columnDefinition: GridColDef = {
            ...col,
            // As DataGrid prepares to render a cell within a column, the value for the cell is obtained based on the field name, then (potentially) modified via a chain of functions: row[field] -> valueGetter -> valueFormatter -> renderCell.
            // The following props ensure that any strings being passed through that chain are sanitized for CSV export, so that popular spreadsheet programs cannot interpret cells as executable formulas.
            valueGetter: getSanitizingValueGetterForColumn(col),
            valueFormatter: getSanitizingValueFormatterForColumn(col),
        };

        // Although it may appear that this if-statement could be collapsed into the `columnDefinition` above, it cannot be.
        // `filterOperators` must be defined if and only if we are overwriting the default operators for the column's `type`.
        // In short: MUI DataGrid interprets `filterOperators: undefined` as "I don't want any filter operations", whereas it interprets no `filterOperators` at all as "Use the default filter operations for this column type".
        if (col.type === 'string' || col.type === undefined) {
            columnDefinition.filterOperators = getGridStringOperators().concat([gridFilterOption()]);
        }

        return columnDefinition;
    });

    const dataGridSx = {
        backgroundColor: 'white',
        border: 0,
        '& .MuiDataGrid-filterIcon': {
            visibility: 'visible',
        },
        '& .MuiDataGrid-menuIcon': {
            visibility: 'visible',
            width: '30px',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: '0.688rem',
            textTransform: 'uppercase',
            color: '#053c59',
        },
        '& .MuiDataGrid-cell': {
            fontFamily: 'Montserrat',
            fontWeight: '500',
            color: '#053c59',
            fontSize: '0.813rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'block',
            paddingTop: '12px',
        },
        '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
        },
    };

    return (
        <PageCell>
            <div className={styles.dataGridContainer}>
                <div className={styles.dataGrid}>
                    <DataGridPro initialState={initialState} disableRowSelectionOnClick disableVirtualization={props.disableVirtualization} columns={columns} getRowId={props.getRowId} rows={props.rows} slots={{ toolbar: CustomToolbar }} slotProps={{ toolbar: { title: props.title, fileName: props.fileName } }} sx={dataGridSx} />
                </div>
            </div>
        </PageCell>
    );
};
