/*
	ControlGroupListingFilter.tsx -- Component for viewing controls (TSPs) in a Filter format.
*/

import { faBars, faThLarge } from '@fortawesome/free-solid-svg-icons';

import { IconButton } from 'Components/Buttons/IconButton';
import { ControlListingView } from 'Models/OperationalControls';

import styles from './ControlGroupListingBodyToolbar.module.css';

export interface ControlGroupListingBodyToolbarProps {
    switchListingView: (view: ControlListingView) => void;
    activeControlListingView: ControlListingView;
}

export const ControlGroupListingBodyToolbar = (props: ControlGroupListingBodyToolbarProps): JSX.Element => {
    return (
        <div className={styles.controlListingFilter}>
            <div className={styles.tableAndGridButtonPanel}>
                <IconButton aria-label="Switch to Control Group Grid View" onClick={() => props.switchListingView(ControlListingView.GRID)} fontAwesomeImage={faThLarge} disabled={props.activeControlListingView === ControlListingView.GRID} />
                <IconButton aria-label="Switch to Control Group List View" onClick={() => props.switchListingView(ControlListingView.LIST)} fontAwesomeImage={faBars} disabled={props.activeControlListingView === ControlListingView.LIST} />
            </div>
        </div>
    );
};
