import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'react-bootstrap';

import { DashboardApi } from 'Api/Dashboards/DashboardApi';
import { Button } from 'Components/Buttons/Buttons';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { FFIECCATMaturityLevel, targetMaturitySelectOptions } from 'Models/Dashboards';

export interface TargetMaturityModalProps {
    hideModal: () => void;
    dashboardApi: DashboardApi;
}

export const TargetMaturityModal = (props: TargetMaturityModalProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [targetMaturity, setTargetMaturity] = useState<FFIECCATMaturityLevel>();

    useEffect(() => {
        const getTargetMaturity = async () => {
            try {
                const response = await props.dashboardApi.getTargetMaturity();
                setTargetMaturity(response.data);
            } catch (error) {
                setFailureMessage(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        getTargetMaturity();
    }, [props.dashboardApi]);

    const handleSelectChange = (value: ChangeEventType): void => {
        setTargetMaturity(value as FFIECCATMaturityLevel);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        setIsSubmitting(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);

        // This covers the scenario where the API query to get the existing value fails and targetMaturity is undefined, however unlikely that may be.
        if (!targetMaturity || !Object.values(FFIECCATMaturityLevel).includes(targetMaturity)) {
            setFailureMessage('Select a valid target maturity.');
            setIsSubmitting(false);
            return;
        }

        try {
            await props.dashboardApi.setTargetMaturity({ target_maturity: targetMaturity });
            setSuccessMessage('Target maturity saved.');
        } catch (error) {
            setFailureMessage(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (isLoading) {
        return (
            <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className={'modalFromBody'}>
                    <Placeholder />
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                {successMessage && <Alert variant="success">{successMessage}</Alert>}
                {failureMessage && <Alert variant="danger">{failureMessage}</Alert>}
                <Form noValidate onSubmit={handleSubmit}>
                    <ModalHeader text="Target Maturity" secondaryText="Select the FFIEC CAT maturity level your organization is currently targeting." />
                    <FormFieldSelect formFieldId="targetMaturity" formFieldLabel="Target Maturity" isRequiredField={true} options={targetMaturitySelectOptions} selectedOption={targetMaturity} handleChange={handleSelectChange} />
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        <Button variant="submit" isLoading={isSubmitting} loadingText="Saving...">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
