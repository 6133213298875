import { faCheck, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import { Button } from 'Components/Buttons/Buttons';
import { ModalHeader } from 'Components/Modal/ModalHeader';
import { Text } from 'Components/Text/Text';
import { RiskRating, RiskRatingAsString, Service, ThirdPartyContact } from 'Models/TPRM';

import styles from './ConfirmSubmitIrqModal.module.css';

export interface ConfirmSubmitIrqModalProps {
    service: Service;
    hasExistingAssessment: boolean;
    inherentRiskRating: RiskRating;
    hideModal: () => void;
    onConfirm: (thirdPartyContacts: ThirdPartyContact[]) => void;
    hasValidDDQConfiguration: boolean;
}

export const ConfirmSubmitIrqModal = (props: ConfirmSubmitIrqModalProps): JSX.Element => {
    // Keep track of which Third-Party Service contacts should receive the DDQ email.
    // By default, all Third-Party Service contacts with a valid email address are selected if there is a valid DDQ configuration (at least 1 question for the selected risk rating), otherwise none of the Third-Party Service contacts are selected.
    const [selectedThirdPartyContacts, setSelectedThirdPartyContacts] = useState<ThirdPartyContact[]>(props.hasValidDDQConfiguration ? props.service.vendor_contacts.filter((third_party_contact) => third_party_contact.email_address !== undefined) : []);

    const existingAssessmentText = (() => {
        if (!props.hasExistingAssessment) {
            return <Text>Beginning the risk workflow process will make the third-party questionnaire, control assessment, and final report available for changes.</Text>;
        } else {
            const inherentRiskRatingString = RiskRatingAsString(props.inherentRiskRating);

            return <Text>The third-party questionnaire will be regenerated based on your current configuration for third-party services with {inherentRiskRatingString} inherent risk. Existing third-party responses and control assessments will be carried forward, except for questions whose configuration has changed.</Text>;
        }
    })();

    const notifyThirdPartyContactText = (() => {
        if (!(props.service.vendor_contacts.length > 0 && props.service.vendor_contacts.some((vendor_contact) => vendor_contact.email_address !== undefined))) {
            return <Text noStyles={true}>There are no third-party service contacts with a valid email address. The third-party questionnaire cannot be sent.</Text>;
        } else if (!props.hasValidDDQConfiguration) {
            return (
                <Text noStyles={true}>
                    The third-party questionnaire does not have any questions configured for the selected risk rating. <b>The third-party service contacts will not be sent a notification.</b>
                </Text>
            );
        } else {
            return (
                <>
                    <Text>Select third-party service contacts to notify via email that the new third-party questionnaire is ready. Only these individuals will be able to register and complete the third-party questionnaire. This will not reset credentials of any third-party service contacts who have already registered.</Text>
                    {props.service.vendor_contacts
                        .filter((thirdPartyContact) => thirdPartyContact.email_address !== undefined)
                        .map((thirdPartyContact, index) => (
                            <div key={index} className={styles.contactInfo}>
                                <Checkbox
                                    checked={selectedThirdPartyContacts.includes(thirdPartyContact)}
                                    color="default"
                                    onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                        const isChecked = event.currentTarget.checked;
                                        setSelectedThirdPartyContacts((prevSelectedThirdPartyContacts) => {
                                            if (isChecked) {
                                                return [...prevSelectedThirdPartyContacts, thirdPartyContact];
                                            } else {
                                                return prevSelectedThirdPartyContacts.filter((contact) => contact !== thirdPartyContact);
                                            }
                                        });
                                    }}
                                />
                                <Text noStyles={true}>{thirdPartyContact.email_address}</Text>
                            </div>
                        ))}
                </>
            );
        }
    })();

    return (
        <Modal show onHide={props.hideModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className={'modalFromBody'}>
                <Form noValidate>
                    <ModalHeader text={props.hasExistingAssessment ? 'Overwrite Inherent Risk Rating' : 'Begin Risk Workflow Process'} secondaryText={`${props.service.vendor_name} - ${props.service.name}`} />
                    {existingAssessmentText}
                    {notifyThirdPartyContactText}
                    <div className={'modalFormButtonContainer'}>
                        <Button variant="secondary" onClick={props.hideModal} fontAwesomeImage={faTimes}>
                            CLOSE
                        </Button>
                        {props.hasExistingAssessment ? (
                            <Button variant="danger" onClick={() => props.onConfirm(selectedThirdPartyContacts)} fontAwesomeImage={faPencilAlt}>
                                OVERWRITE
                            </Button>
                        ) : (
                            <Button variant="primary" onClick={() => props.onConfirm(selectedThirdPartyContacts)} fontAwesomeImage={faCheck}>
                                BEGIN
                            </Button>
                        )}
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};
