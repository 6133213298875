import styles from './PageContent.module.css';

export interface PageContentProps {
    children?: React.ReactNode;
    removePadding?: boolean;
}

export const PageContent: React.FC<PageContentProps> = ({ children, removePadding }) => {
    return <div className={`${styles.pageContent} ${removePadding === true && styles.removePadding}`}>{children}</div>;
};
