import { Text } from 'Components/Text/Text';

import styles from './Page.module.css';

/**
 * @param headerBreadcrumb  - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param headerTitle       - The title of the page.
 * @param headerButtons     - Non-form buttons that are right-justified but on the same vertical alignment as the page title. NOTE: This is the default location for non-form buttons on a page. Buttons that affect a form should be part of the form itself and are neither implemented nor documented in this module.
 * @param headerDescription - Full sentences explaining the purpose of the page. This is optional, but can be useful for providing more context to the user. It should almost always be a string, but in rare cases, such as rendering Control text, it can be a React node.
 * @param body              - The page body is everything below the page header. It is the main content of the page, and is composed of one or more page body sections.
 */
export interface PageProps {
    headerBreadcrumb?: React.ReactNode;
    headerTitle: string;
    headerButtons?: React.ReactNode;
    headerDescription?: React.ReactNode | string;
    body: PageBodySectionProps[];
}
/**
 * A dynamic "page" of SummIT Security. Note that the <Nav> and <Header> components are static siblings of the <Page> component and are neither implemented nor documented in this module
 */
export const Page = (props: PageProps): JSX.Element => {
    return (
        <>
            <PageHeader breadcrumb={props.headerBreadcrumb} title={props.headerTitle} buttons={props.headerButtons} description={props.headerDescription} />
            <PageBody pageBodySections={props.body} />
        </>
    );
};

/**
 * @param breadcrumb    - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param title         - The title of the page.
 * @param buttons       - Non-form buttons that are right-justified but on the same vertical alignment as the page title. NOTE: This is the default location for non-form buttons on a page. Buttons that affect a form should be part of the form itself and are neither implemented nor documented in this module.
 * @param description   - Full sentences explaining the purpose of the page. This is optional, but can be useful for providing more context to the user. It should almost always be a string, but in rare cases, such as rendering Control text, it can be a React node.
 */
interface PageHeaderProps {
    breadcrumb: React.ReactNode;
    title: string;
    buttons?: React.ReactNode;
    description?: React.ReactNode | string;
}
/**
 * The page header is the top section of the page, which contains some information that is common across all pages, such as the breadcrumb and title of the page.
 * WARNING: Do not confuse this with the overall application <Header> component, which is rendered above the "page."
 */
const PageHeader = (props: PageHeaderProps): JSX.Element => {
    return (
        <div className={styles.pageHeader}>
            <div className={styles.pageHeaderContent}>
                {props.breadcrumb}
                <div className={styles.titleAndButtons}>
                    <Text color="darkBlue" variant="Header1">
                        {props.title}
                    </Text>
                    <div className={styles.buttons}>{props.buttons}</div>
                </div>
                {typeof props.description === 'string' ? (
                    <Text color="blue" variant="Header3">
                        {props.description}
                    </Text>
                ) : (
                    props.description
                )}
            </div>
        </div>
    );
};

/**
 * @param pageBodySections - The page body sections that comprise the page body.
 */
interface PageBodyProps {
    pageBodySections: PageBodySectionProps[];
}
/**
 * The page body is everything below the page header. It is the main content of the page, and is composed of one or more page body sections.
 */
const PageBody = (props: PageBodyProps): JSX.Element => {
    return (
        <div className={styles.pageBody}>
            {props.pageBodySections.map((pageBodySection, index) => (
                <PageBodySection key={index} title={pageBodySection.title} buttons={pageBodySection.buttons} content={pageBodySection.content} />
            ))}
        </div>
    );
};

/**
 * @param title     - The title of the page body section. It is useful to include this if there are multiple page body sections on the page to differentiate them, or if it provides more context to the page header. But if the page body section contains tabs (so the tab names would make this redundant), or if this would simply be the same as the page header, then it should be excluded.
 * @param buttons   - Non-form buttons that are right-justified but on the same vertical alignment as the body section title. NOTE: This should only be used when there are multiple body sections or tabs where non-form buttons are needed that only apply to one body section. Otherwise, non-form buttons go in the page header. Buttons that affect a form should be part of the form itself and are neither implemented nor documented in this module.
 * @param content   - The content of the page body section.
 */
interface PageBodySectionProps {
    title?: string;
    buttons?: React.ReactNode;
    content: React.ReactNode;
}
/**
 * A page body section is a grouping of content within a page. It is the familiar white box with rounded corners. Usually there is one of these per page, but there can be multiple.
 */
const PageBodySection = (props: PageBodySectionProps): JSX.Element => {
    const buildTitleAndButtons = () => {
        if (props.title && props.buttons) {
            return (
                <>
                    <div className={styles.titleAndButtons}>
                        <Text noStyles variant="Header2">
                            {props.title}
                        </Text>
                        <div className={styles.buttons}>{props.buttons}</div>
                    </div>
                    <hr />
                </>
            );
        } else if (props.title) {
            return (
                <>
                    <Text noStyles variant="Header2">
                        {props.title}
                    </Text>
                    <hr />
                </>
            );
        }
    };

    return (
        <div className={styles.pageBodySection}>
            {buildTitleAndButtons()}
            {props.content}
        </div>
    );
};
