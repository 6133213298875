/*
	ExceptionsIssues.tsx -- Page for viewing and creating exceptions and issues.
*/
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { ExceptionsApi } from 'Api/Exceptions/ExceptionsApi';
import { IssuesApi } from 'Api/Issues/IssuesApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { useCachedData } from 'Components/Context/CachedDataContext';
import { Page } from 'Components/Page/Page';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { Text } from 'Components/Text/Text';
import { GENERIC_ERROR_MESSAGE } from 'Config/Errors';
import { IssuesExceptionsModule } from 'Models/Issues';
import { ThirdPartyResponseWithServices } from 'Models/TPRM';

import { ExceptionsTab, ExceptionsTabProps } from './Exceptions/Components/ExceptionsTab/ExceptionsTab';
import { IssuesTab, IssuesTabProps } from './Issues/Components/IssuesTab/IssuesTab';

enum ExceptionsIssuesTabKey {
    ISSUES = 'issues',
    EXCEPTIONS = 'exceptions',
}

interface ExceptionsIssuesOperationalControlsProps {
    type: IssuesExceptionsModule.CONTROLS;
    issuesApi: IssuesApi;
    exceptionsApi: ExceptionsApi;
    documentApi: DocumentApi;
    tprmApi?: never; // Typed like this so that `tprmApi` can be used unconditionally as a `useEffect` dependency.
}

interface ExceptionsIssuesTprmProps {
    type: IssuesExceptionsModule.TPRM;
    issuesApi: IssuesApi;
    exceptionsApi: ExceptionsApi;
    documentApi: DocumentApi;
    tprmApi: TPRMApi;
}

export type ExceptionsIssuesProps = ExceptionsIssuesOperationalControlsProps | ExceptionsIssuesTprmProps;

/**
 * Renders two tabs: one for issues and one for exceptions.
 */
export const ExceptionsIssues = (props: ExceptionsIssuesProps) => {
    const cachedData = useCachedData();
    const location = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(location.search);
    const thirdPartyIdQueryParam = query.get('thirdPartyId') ?? undefined;
    const [thirdParties, setThirdParties] = useState<ThirdPartyResponseWithServices[]>(); // These are actually only fetched for TPRM Issues/Exceptions.
    const [pageErrorOccurred, setPageErrorOccurred] = useState(false);

    useEffect(() => {
        const getThirdParties = async (): Promise<void> => {
            if (props.type !== IssuesExceptionsModule.TPRM) {
                setThirdParties([]);
                return;
            }

            try {
                const response = await props.tprmApi.getThirdParties();
                setThirdParties(response.data);
            } catch (error) {
                setPageErrorOccurred(true);
            }
        };

        getThirdParties();
    }, [props.tprmApi, props.type]);

    /**
     * Determine which tab to load by default.
     */
    const getDefaultTab = (): ExceptionsIssuesTabKey => {
        if (location.hash && location.hash.length > 0) {
            const strippedHash = location.hash.substring(1);
            if (Object.values(ExceptionsIssuesTabKey).includes(strippedHash as ExceptionsIssuesTabKey)) {
                return strippedHash as ExceptionsIssuesTabKey;
            }
        }

        return ExceptionsIssuesTabKey.ISSUES;
    };

    /**
     * Update the URL when the user changes to a new tab. This allows the user to return to the most recently used tab, rather than always landing on the default tab.
     * @param selectedTab comes from the eventKey attribute of the <Tab /> component.
     */
    const handleTabChange = (selectedTab?: string): void => {
        navigate(`${location.pathname}#${selectedTab}`, { replace: true });
    };

    if (pageErrorOccurred) {
        return <Text>{GENERIC_ERROR_MESSAGE}</Text>;
    }

    if (!thirdParties) {
        return <Placeholder />;
    }

    const issuesTabProps: IssuesTabProps = (() => {
        switch (props.type) {
            case IssuesExceptionsModule.CONTROLS:
                return {
                    issuesType: IssuesExceptionsModule.CONTROLS,
                    issuesApi: props.issuesApi,
                    documentApi: props.documentApi,
                    users: cachedData.users,
                };
            case IssuesExceptionsModule.TPRM:
                return {
                    issuesType: IssuesExceptionsModule.TPRM,
                    issuesApi: props.issuesApi,
                    documentApi: props.documentApi,
                    users: cachedData.users,
                    thirdParties: thirdParties,
                    thirdPartyIdQueryParam: thirdPartyIdQueryParam,
                };
        }
    })();

    const exceptionsTabProps: ExceptionsTabProps = (() => {
        switch (props.type) {
            case IssuesExceptionsModule.CONTROLS:
                return {
                    exceptionsType: IssuesExceptionsModule.CONTROLS,
                    exceptionsApi: props.exceptionsApi,
                    documentApi: props.documentApi,
                    users: cachedData.users,
                };
            case IssuesExceptionsModule.TPRM:
                return {
                    exceptionsType: IssuesExceptionsModule.TPRM,
                    exceptionsApi: props.exceptionsApi,
                    documentApi: props.documentApi,
                    users: cachedData.users,
                    thirdParties: thirdParties,
                    thirdPartyIdQueryParam: thirdPartyIdQueryParam,
                };
        }
    })();

    return (
        <Page
            headerTitle="Issues and Exceptions"
            body={[
                {
                    content: (
                        <PrimaryTabs defaultActiveTab={getDefaultTab()} onSelect={handleTabChange} removePadding>
                            <Tab title="Issues" eventKey={ExceptionsIssuesTabKey.ISSUES}>
                                <IssuesTab {...issuesTabProps} />
                            </Tab>
                            <Tab title="Exceptions" eventKey={ExceptionsIssuesTabKey.EXCEPTIONS}>
                                <ExceptionsTab {...exceptionsTabProps} />
                            </Tab>
                        </PrimaryTabs>
                    ),
                },
            ]}
        />
    );
};
