import { RiskRatingHistoryResponse, RiskResponse } from 'Models/RiskRegister';

import styles from './RiskCells.module.css';
import { RiskCell, RiskCellPropsCurrentRisk, RiskCellPropsInherentRisk, RiskCellPropsTargetRisk } from '../RiskCell/RiskCell';

const MAX_HISTORICAL_VALUES_TO_DISPLAY = 5;

const getMostRecentDefinedValues = (histories: RiskRatingHistoryResponse[], type: 'total_inherent_risk' | 'total_current_risk'): { value: number; timestamp: string }[] => {
    const definedValues = histories.filter((history) => history[type] !== undefined).map((history) => ({ value: history[type] as number, timestamp: history.timestamp }));
    return definedValues.slice(Math.max(definedValues.length - MAX_HISTORICAL_VALUES_TO_DISPLAY, 0));
};

export interface RiskCellsProps {
    risk: RiskResponse;

    /**
     * The complete history of risk ratings for a risk, where the most recent rating is the first element in the array.
     */
    riskRatingHistory: RiskRatingHistoryResponse[];
}

/**
 * Renders one `RiskCell` each for a Risk's Inherent Risk, Current Residual Risk, and Target Residual Risk.
 * Undefined historical values are ignored. Up to five of the most recent defined values are displayed per `RiskCell`.
 */
export const RiskCells = (props: RiskCellsProps) => {
    const historiesInAscendingChronologicalOrder = [...props.riskRatingHistory].reverse();

    const inherentRiskProps: RiskCellPropsInherentRisk = {
        type: 'Inherent Risk',
        impact: props.risk.inherent_risk_impact,
        probability: props.risk.inherent_risk_probability,
        total: props.risk.total_inherent_risk,
        trend: getMostRecentDefinedValues(historiesInAscendingChronologicalOrder, 'total_inherent_risk'),
    };

    const currentRiskProps: RiskCellPropsCurrentRisk = {
        type: 'Current Residual Risk',
        impact: props.risk.current_risk_impact,
        probability: props.risk.current_risk_probability,
        total: props.risk.total_current_risk,
        trend: getMostRecentDefinedValues(historiesInAscendingChronologicalOrder, 'total_current_risk'),
    };

    const targetRiskProps: RiskCellPropsTargetRisk = {
        type: 'Target Residual Risk',
        impact: props.risk.target_risk_impact,
        probability: props.risk.target_risk_probability,
        total: props.risk.total_target_risk,
    };

    return (
        <div className={styles.container}>
            <div className={styles.cellWrapper}>
                <RiskCell {...inherentRiskProps} />
            </div>
            <div className={styles.cellWrapper}>
                <RiskCell {...currentRiskProps} />
            </div>
            <div className={styles.cellWrapper}>
                <RiskCell {...targetRiskProps} />
            </div>
        </div>
    );
};
