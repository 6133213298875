import * as React from 'react';

import { PrimaryTabs, Tab } from 'Components/Tabs/PrimaryTabs/PrimaryTabs';
import { Text } from 'Components/Text/Text';
import { DashboardType } from 'Models/Dashboards';

import styles from './PageDashboard.module.css';

/**
 * @param headerBreadcrumb  - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param headerTitle       - The title of the page.
 * @param dashlets          - List of dashlets that comprise a dashboard. Dashlets can be nested within one another to create a grid of dashlets.
 */
interface PageDashboardProps {
    headerBreadcrumb?: React.ReactNode;
    headerTitle: string;
    dashlets: DashletProps[];
}
/**
 * A dashboard implemented as a single page (no tabs).
 */
export const PageDashboard = (props: PageDashboardProps): JSX.Element => {
    return (
        <div className={styles.background}>
            <PageDashboardHeader breadcrumb={props.headerBreadcrumb} title={props.headerTitle} />
            <PageDashboardBody dashlets={props.dashlets} includePadding={true} />
        </div>
    );
};

/**
 * @param title         - The title of the tab.
 * @param dashboardType - Used to determine which dashboard to display when the tab is selected.
 * @param dashlets      - List of dashlets that comprise a dashboard. Dashlets can be nested within one another to create a grid of dashlets.
 */
interface PageDashboardTabProps {
    title: string;
    dashboardType: DashboardType;
    dashlets: DashletProps[];
}
/**
 * @param headerBreadcrumb  - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param headerTitle       - The title of the page.
 * @param defaultTab        - The tab that is selected by default.
 * @param onTabChange       - Function that is called when the tab is changed.
 * @param tabs              - List of tabs that comprise the dashboard. Each tab has a title and a list of dashlets.
 */
interface PageDashboardTabsProps {
    headerBreadcrumb?: React.ReactNode;
    headerTitle: string;
    defaultTab: string;
    onTabChange?: (eventKey?: string) => void;
    tabs: PageDashboardTabProps[];
}
/**
 * A dashboard implemented with tabs.
 */
export const PageDashboardTabs = (props: PageDashboardTabsProps): JSX.Element => {
    return (
        <div className={styles.background}>
            <PageDashboardHeader breadcrumb={props.headerBreadcrumb} title={props.headerTitle} />
            <div className={styles.pageDashboardTabs}>
                <div>
                    <PrimaryTabs defaultActiveTab={props.defaultTab} onSelect={props.onTabChange} removePadding transparent tabButtonTextColor={'white'}>
                        {props.tabs.map((tab, index) => (
                            <Tab key={index} eventKey={tab.dashboardType} title={tab.title} unmountOnExit={true}>
                                <PageDashboardBody dashlets={tab.dashlets} includePadding={false} />
                            </Tab>
                        ))}
                    </PrimaryTabs>
                </div>
            </div>
        </div>
    );
};

/**
 * @param breadcrumb    - Breadcrumb navigation links for the page. Top level pages do not have a breadcrumb.
 * @param title         - The title of the page.
 */
interface PageDashboardHeaderProps {
    breadcrumb: React.ReactNode;
    title: string;
}
/**
 * The page header is the top section of the page, which contains some information that is common across all pages, such as the breadcrumb and title of the page.
 * WARNING: Do not confuse this with the overall application <Header> component, which is rendered above the "page."
 */
const PageDashboardHeader = (props: PageDashboardHeaderProps): JSX.Element => {
    return (
        <div>
            <div className={styles.pageHeaderContent}>
                {props.breadcrumb}
                <Text color="white" variant="Header1">
                    {props.title}
                </Text>
            </div>
        </div>
    );
};

/**
 * @param dashlets - List of dashlets that comprise a dashboard. Dashlets can be nested within one another to create a grid of dashlets.
 */
interface PageDashboardBodyProps {
    dashlets: DashletProps[];
    includePadding: boolean;
}
/**
 * The wrapper around the top level of dashlets.
 */
const PageDashboardBody = (props: PageDashboardBodyProps): JSX.Element => {
    return (
        <div className={props.includePadding ? styles.pageBodyWithPadding : styles.pageBodyNoPadding}>
            <div className={styles.pageBodySectionAlignmentColumn}>
                {props.dashlets.map((dashlet, index) => (
                    <Dashlet key={index} title={dashlet.title} content={dashlet.content} dashlets={dashlet.dashlets} verticalAlignment={false} />
                ))}
            </div>
        </div>
    );
};

/**
 * @param title             - The title of the dashlet. All dashlets should have a title and this is the default method for displaying the title, but it is optional because some dashlets have customized headers, such as if the title is a hyperlink, or if there are drop-down filters or buttons aligned with the title.
 * @param content           - The content of the dashlet.
 * @param dashlets          - Nested dashlets that are displayed within this dashlet. This is used to create a grid of dashlets.
 * @param verticalAlignment - If true, the dashlets will be displayed in a column. If false, the dashlets will be displayed in a row. NOTE: Do not manually provide this prop, it is automatically provided by the parent component (either PageDashboardBody or another Dashlet) when dashlets are nested.
 */
interface DashletProps {
    title?: string;
    content?: React.ReactNode;
    dashlets?: DashletProps[];
    verticalAlignment?: boolean;
}
/**
 * A dashlet is a grouping of content within a dashboard. It is the familiar dark blue box with rounded corners. Multiple dashlets on a page are automatically aligned in an alternating row/column layout based on nesting.
 */
const Dashlet = (props: DashletProps): JSX.Element => {
    if (props.dashlets) {
        return (
            <div className={props.verticalAlignment ? styles.pageBodySectionAlignmentColumn : styles.pageBodySectionAlignmentRow}>
                {props.dashlets.map((dashlet, index) => (
                    <Dashlet key={index} title={dashlet.title} content={dashlet.content} dashlets={dashlet.dashlets} verticalAlignment={!dashlet.verticalAlignment} />
                ))}
            </div>
        );
    } else {
        return (
            <div className={styles.dashlet}>
                <Text variant="Header2" color="white">
                    {props.title}
                </Text>
                {props.content}
            </div>
        );
    }
};
