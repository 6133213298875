import { OptionType } from './Types/GlobalType';

export enum ScheduleFrequency {
    DAYS = 'Days',
    MONTHS = 'Months',
    YEARS = 'Years',
}

export type ScheduleFrequencyKeys = keyof typeof ScheduleFrequency;

export const ScheduleFrequencySelectOptions: OptionType[] = [
    {
        value: 'DAYS',
        label: ScheduleFrequency.DAYS,
    },
    {
        value: 'MONTHS',
        label: ScheduleFrequency.MONTHS,
    },
    {
        value: 'YEARS',
        label: ScheduleFrequency.YEARS,
    },
];
