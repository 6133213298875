/*
	ControlGridView.tsx -- Component for viewing controls (TSPs) in a grid format.
*/
import { ControlCard } from 'Components/ControlCard/ControlCard';
import { controlTextToString, getHumanReadableControlIdFromControl } from 'Helpers/ControlFormatter/ControlFormatter';
import { getFrameworkGroupControlURL } from 'Helpers/URLBuilder/URLBuilder';
import { OperationalControl } from 'Models/OperationalControls';

import styles from './ControlGridView.module.css';

export interface ControlGridViewProps {
    controlResponse: OperationalControl[];
}

export const ControlGridView = (props: ControlGridViewProps): JSX.Element => {
    return (
        <div className={styles.controlListingGridContainer}>
            {props.controlResponse.map((controlResponse: OperationalControl) => (
                <ControlCard key={controlResponse.identifier} effectiveness={controlResponse.effectiveness.control_effectiveness} description={controlTextToString(controlResponse.metadata.control_text)} link={getFrameworkGroupControlURL(controlResponse.identifier)} linkText="VIEW CONTROL" title={getHumanReadableControlIdFromControl(controlResponse)} />
            ))}
        </div>
    );
};
