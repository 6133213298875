import { cloneDeep } from 'lodash-es';
import { useState } from 'react';

import { useCachedData } from 'Components/Context/CachedDataContext';
import { SortDirection } from 'Components/Table/SortableTableHeader/SortableTableHeader';
import { Text } from 'Components/Text/Text';
import { compareUsersBySubjectForSorting } from 'Helpers/UserUtils';
import { ThirdPartyListingSortProperty, ThirdPartyResponseWithServices } from 'Models/TPRM';
import { Filter, Filters } from 'Models/Types/GlobalType';

import styles from './ThirdPartyListing.module.css';
import { ThirdPartyListingBodyToolbar, ThirdPartyListingBodyToolbarProps } from './ThirdPartyListingBodyToolbar/ThirdPartyListingBodyToolbar';
import { ThirdPartyListingGridView, ThirdPartyListingGridViewProps } from './ThirdPartyListingGridView/ThirdPartyListingGridView';
import { ThirdPartyListingTableView, ThirdPartyListingTableViewProps } from './ThirdPartyListingTableView/ThirdPartyListingTableView';

export interface ThirdPartyListingProps {
    thirdParties: ThirdPartyResponseWithServices[];
    selectedDeleteThirdParty: (thirdPartyId: string) => void;
    selectedModifyThirdParty: (thirdPartyId: string) => void;
    selectedCreateServiceForThirdParty: (thirdParty: ThirdPartyResponseWithServices) => void;
    selectedViewAllServicesForThirdParty: (thirdParty: ThirdPartyResponseWithServices) => void;
    selectedViewFolders: (thirdPartyId: string) => void;
}

export enum ThirdPartyListingView {
    LIST = 'list',
    GRID = 'grid',
}

export const ThirdPartyListing = (props: ThirdPartyListingProps): JSX.Element => {
    const cachedData = useCachedData();

    const [activeThirdPartyListingView, setActiveThirdPartyListingView] = useState<ThirdPartyListingView>(ThirdPartyListingView.GRID);
    const [selectedFilterOptions, setSelectedFilterOptions] = useState<Filters>({});
    const [currentSortDirection, setCurrentSortDirection] = useState<SortDirection>(SortDirection.ASC);
    const [currentlySortedBy, setCurrentlySortedBy] = useState<string>(ThirdPartyListingSortProperty.NAME);

    const switchServiceListingView = (activeServiceListingView: ThirdPartyListingView): void => {
        setActiveThirdPartyListingView(activeServiceListingView);
    };

    const setCurrentSort = (newSort: string, newSortDirection: SortDirection): void => {
        setCurrentlySortedBy(newSort as ThirdPartyListingSortProperty);
        setCurrentSortDirection(newSortDirection);
    };

    const setCurrentFilterSelection = (updatedFilter: Filter | Filter[]): void => {
        const newSelectedFilterOptions = cloneDeep(selectedFilterOptions);

        if (updatedFilter instanceof Array) {
            updatedFilter.forEach((filter) => {
                if (filter.value.length > 0) {
                    newSelectedFilterOptions[filter.key] = filter;
                } else {
                    delete newSelectedFilterOptions[filter.key];
                }
            });
        } else {
            if (updatedFilter.value.length > 0) {
                newSelectedFilterOptions[updatedFilter.key] = updatedFilter;
            } else {
                delete newSelectedFilterOptions[updatedFilter.key];
            }
        }

        setSelectedFilterOptions(newSelectedFilterOptions);
    };

    const filterAndSortThirdParties = (): ThirdPartyResponseWithServices[] => {
        let filteredThirdParties = [...props.thirdParties];
        if (Object.keys(selectedFilterOptions).length > 0) {
            for (const filter of Object.values(selectedFilterOptions)) {
                filteredThirdParties = filteredThirdParties.filter((thirdParty) => {
                    return filter.value.includes(thirdParty[filter.key]);
                });
            }
        }
        if (!currentlySortedBy) {
            return filteredThirdParties;
        }

        return filteredThirdParties.sort((thirdPartyA, thirdPartyB) => {
            let sortResult = 0;
            switch (currentlySortedBy) {
                case ThirdPartyListingSortProperty.NAME:
                    sortResult = thirdPartyA.name.localeCompare(thirdPartyB.name);
                    break;
                case ThirdPartyListingSortProperty.CREATED_TIME:
                    sortResult = thirdPartyA.created_time < thirdPartyB.created_time ? 1 : -1;
                    break;
                case ThirdPartyListingSortProperty.THIRD_PARTY_MANAGER_USER_ID:
                    sortResult = compareUsersBySubjectForSorting(thirdPartyA.vendor_manager_user_id, thirdPartyB.vendor_manager_user_id, cachedData.users);
                    break;
                default:
                    break;
            }

            return currentSortDirection === SortDirection.ASC ? sortResult : -sortResult;
        });
    };

    if (props.thirdParties.length === 0) {
        return (
            <div className={styles.zeroStateContainer}>
                <Text>You have not created any third parties. To do so, select "CREATE NEW THIRD PARTY" in the top-right.</Text>
            </div>
        );
    } else {
        const thirdParties = filterAndSortThirdParties();

        const thirdPartyListingBodyToolbarProps: ThirdPartyListingBodyToolbarProps = {
            activeThirdPartyListingView,
            switchListingView: switchServiceListingView,
            filterThirdParties: setCurrentFilterSelection,
            sortCardsBy: setCurrentSort,
            currentlySortedBy,
        };

        const thirdPartyListingGridViewProps: ThirdPartyListingGridViewProps = {
            thirdParties,
            selectedCreateServiceForThirdParty: props.selectedCreateServiceForThirdParty,
            selectedModifyThirdParty: props.selectedModifyThirdParty,
            selectedDeleteThirdParty: props.selectedDeleteThirdParty,
            selectedViewFolders: props.selectedViewFolders,
        };

        const thirdPartyListingTableViewProps: ThirdPartyListingTableViewProps = {
            thirdParties,
            applySorting: setCurrentSort,
            currentlySortedBy,
            currentSortDirection,
            selectedModifyThirdParty: props.selectedModifyThirdParty,
            selectedDeleteThirdParty: props.selectedDeleteThirdParty,
            selectedCreateServiceForThirdParty: props.selectedCreateServiceForThirdParty,
            selectedViewAllServicesForThirdParty: props.selectedViewAllServicesForThirdParty,
            selectedViewFolders: props.selectedViewFolders,
        };

        return (
            <>
                <ThirdPartyListingBodyToolbar {...thirdPartyListingBodyToolbarProps} />
                {{ grid: <ThirdPartyListingGridView {...thirdPartyListingGridViewProps} />, list: <ThirdPartyListingTableView {...thirdPartyListingTableViewProps} /> }[activeThirdPartyListingView]}
            </>
        );
    }
};
