import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { DocumentApi } from 'Api/Document/DocumentApi';
import { TPRMApi } from 'Api/TPRM/TPRMApi';
import { Button } from 'Components/Buttons/Buttons';
import { OverflowMenu } from 'Components/Buttons/OverflowMenu';
import { PageBackground } from 'Components/Containers/PageBackground/PageBackground';
import { PageContent } from 'Components/Containers/PageContent/PageContent';
import { FileDragAndDrop, FileDragAndDropProps } from 'Components/FileDragAndDrop/FileDragAndDrop';
import { UploadedFileAndState } from 'Components/Files/UploadedFileAndState';
import { ChangeEventType, FormFieldSelect } from 'Components/FormField/FormFieldSelect/FormFieldSelect';
import { FormFieldTextArea } from 'Components/FormField/FormFieldTextArea/FormFieldTextArea';
import { RadioButtonGroup } from 'Components/FormField/RadioButtonGroup/RadioButtonGroup';
import { Breadcrumb, BreadcrumbLink, BreadcrumbText } from 'Components/Nav/Breadcrumb/Breadcrumb';
import { Page } from 'Components/Page/Page';
import { Placeholder } from 'Components/Placeholder/Placeholder';
import { Table, TableBody, TableCellDefaultText, TableOverflowCell, TableRow } from 'Components/Table/Table/Table';
import { Text } from 'Components/Text/Text';
import { TextToast } from 'Components/Toast/Toast';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
import { VisualLabel } from 'Components/VisualLabel/VisualLabel';
import { UNAUTHORIZED_MESSAGE } from 'Config/Errors';
import { DASHBOARDS, SERVICES, THIRD_PARTIES, TPRM } from 'Config/Paths';
import { TPRM_IRQ_ADDITIONAL_INFORMATION, TPRM_IRQ_FILES } from 'Config/Tooltips';
import { isForbiddenResponseError } from 'Helpers/Auth/ResponseUtil';
import { downloadDocument, submitRequestWithFiles } from 'Helpers/FileUtils';
import { Navigator } from 'Helpers/Navigator';
import { useFileDragAndDrop } from 'Hooks/FileDragAndDrop';
import { FileState, UploadedFile } from 'Models/Files';
import { CustomerDetailsAnnualVolume, DataClassification, DataStorageLocation, InherentRiskEvidenceResponse, Question1Options, Question2Options, Question3Options, RiskRating, RiskRatingAsString, RiskRatingSelectOptions, Service, ServiceAssessmentState, SetInherentRiskEvidenceRequest, ThirdPartyContact, calculateRiskScore } from 'Models/TPRM';

import { ClearIRQModal, ClearIRQModalProps } from './ClearIRQModal/ClearIRQModal';
import { ConfirmSubmitIrqModal, ConfirmSubmitIrqModalProps } from './ConfirmSubmitIrqModal/ConfirmSubmitIrqModal';
import styles from './InherentRiskQuestionnaire.module.css';

export interface UrlParams {
    service_id: string;
    third_party_id: string;
}

export enum Modals {
    ClearIRQModal,
    ConfirmSubmitIrqModal,
    None,
}

export interface InherentRiskQuestionnaireProps {
    documentApi: DocumentApi;
    tprmApi: TPRMApi;
    navigator: Navigator;
}

type ActiveInherentRisks = Exclude<RiskRating, RiskRating.INACTIVE>;
type DDQQuestionCount = Record<ActiveInherentRisks, number>;

export const InherentRiskQuestionnaire = (props: InherentRiskQuestionnaireProps) => {
    const params = useParams<keyof UrlParams>() as UrlParams;
    const [customerDetailsAnnualVolume, setCustomerDetailsAnnualVolume] = useState<CustomerDetailsAnnualVolume>();
    const [dataClassification, setDataClassification] = useState<DataClassification>();
    const [dataStorageLocation, setDataStorageLocation] = useState<DataStorageLocation>();
    const [displayedModal, setDisplayedModal] = useState<Modals>();
    const [existingIRQ, setExistingIRQ] = useState<boolean>();
    const [failureMessage, setFailureMessage] = useState<string>();
    const [irqText, setIrqText] = useState<string>();
    const [isCreatingIRQ, setIsCreatingIRQ] = useState<boolean>();
    const [originalAssessmentState, setOriginalAssessmentState] = useState<ServiceAssessmentState>();
    const [selectedRiskRating, setSelectedRiskRating] = useState<RiskRating | null>();
    const [serviceResponse, setServiceResponse] = useState<Service>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [tprmAccessDenied, setTprmAccessDenied] = useState<boolean>();
    const [thirdPartyServiceTitle, setThirdPartyServiceTitle] = useState<string>();

    const [files, onAddFiles, onRemoveFile, clearFiles] = useFileDragAndDrop();
    const [existingFiles, setExistingFiles] = useState<UploadedFile[]>();
    const [existingFilesToDelete, setExistingFilesToDelete] = useState<string[]>([]);

    const [questionCount, setQuestionCount] = useState<DDQQuestionCount>();

    /**
     * Gets current IRQ data for the third-party service. Must not be called if the third-party service has an inherent risk score of 0.
     */
    const getIRQEvidence = useCallback(async (): Promise<void> => {
        try {
            const irqEvidenceResponse = await props.tprmApi.getIRQEvidence(params.third_party_id, params.service_id);
            const irqEvidence: InherentRiskEvidenceResponse = irqEvidenceResponse.data;
            setCustomerDetailsAnnualVolume(irqEvidence.customer_details_annual_volume);
            setDataClassification(irqEvidence.data_classification);
            setDataStorageLocation(irqEvidence.data_storage_location);
            setExistingIRQ(true);
            setExistingFiles(irqEvidence.files);
            setIrqText(irqEvidence.text);
        } catch (error) {
            handleRequestError(error);
        }
    }, [params.third_party_id, params.service_id, props.tprmApi]);

    /**
     * Fetches details for the third-party service.
     * Also fetches the IRQ data if the third-party service has an inherent risk score assigned, or if `forceGetIrqEvidence` is true. (IRQ re-fetch is forced when the user has uploaded new files, in which case the frontend needs to get the generated file IDs from the backend.)
     */
    const getServiceDetails = useCallback(
        async (forceGetIrqEvidence = false, isFirstRender = false): Promise<void> => {
            try {
                const detailedServiceResponse = await props.tprmApi.getServiceDetails(params.third_party_id, params.service_id);
                setServiceResponse(detailedServiceResponse.data);
                const thirdPartyServiceTitle = `${detailedServiceResponse.data.vendor_name} - ${detailedServiceResponse.data.name}`; // Needs updated when third-party name is added.
                setThirdPartyServiceTitle(thirdPartyServiceTitle);

                // Only set the original assessment state on the first load of the service.
                // The assessment state is used to set values for some UI elements and can change after a user submits the IRQ.
                if (isFirstRender) {
                    setOriginalAssessmentState(detailedServiceResponse.data.assessment_state);
                }

                if (forceGetIrqEvidence) {
                    await getIRQEvidence();
                } else {
                    if (detailedServiceResponse.data.inherent_risk_score > 0) {
                        setSelectedRiskRating(detailedServiceResponse.data.inherent_risk_score);
                        await getIRQEvidence();
                    } else {
                        setExistingFiles([]);
                        setExistingIRQ(false);
                    }
                }

                const questionnaireConfiguration = await props.tprmApi.getQuestionnaireConfiguration();

                const questionCountMap = { [RiskRating.LOW]: 0, [RiskRating.LOW_MODERATE]: 0, [RiskRating.MODERATE]: 0, [RiskRating.MODERATE_HIGH]: 0, [RiskRating.HIGH]: 0 };

                const questionCountByRiskRating = questionnaireConfiguration.data.control_frameworks
                    .flatMap((framework) => framework.control_groups.flatMap((controlGroup) => controlGroup.controls.flatMap((control) => control.questions.flatMap((question) => question.mapped_risk_ratings))))
                    .reduce((acc, curr) => {
                        if (curr !== RiskRating.INACTIVE) {
                            acc[curr]++;
                        }
                        return acc;
                    }, questionCountMap);

                setQuestionCount(questionCountByRiskRating);
            } catch (error) {
                if (isForbiddenResponseError(error)) {
                    setTprmAccessDenied(true);
                } else {
                    handleRequestError(error);
                }
            }
        },
        [getIRQEvidence, params.third_party_id, params.service_id, props.tprmApi]
    );

    /*
     * Fetches the third-party service details--and IRQ data, if it exists--when the component mounts.
     */
    useEffect(() => {
        getServiceDetails(false, true);
    }, [getServiceDetails]);

    const handleRequestError = (error: Error): void => console.error('Error: ', error);

    /**
     * Set the inherent risk rating for the Third-Party Service.
     * @param thirdPartyContacts A list of Third-Party Service contacts to notify about the Due Diligence Questionnaire (DDQ).
     * @returns
     */
    const setIRQ = async (thirdPartyContacts: ThirdPartyContact[]): Promise<void> => {
        if (!selectedRiskRating) {
            setFailureMessage('Please provide a risk value.');
            setSuccessMessage(undefined);
            return;
        }

        setIsCreatingIRQ(true);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);

        try {
            const newFiles = files.map((file) => ({ file: file }));
            const hasNewDocumentation = newFiles.length !== 0;

            await submitRequestWithFiles(props.documentApi, newFiles, async (filesToBeUploaded) => {
                const setInherentRiskEvidenceRequest: SetInherentRiskEvidenceRequest = {
                    data_classification: dataClassification,
                    customer_details_annual_volume: customerDetailsAnnualVolume,
                    data_storage_location: dataStorageLocation,
                    inherent_risk_score: selectedRiskRating,
                    third_party_contacts: thirdPartyContacts,
                    file_updates: { new_files: filesToBeUploaded, existing_files_to_delete: existingFilesToDelete },
                    text: irqText,
                };

                await props.tprmApi.setIRQ(params.third_party_id, params.service_id, setInherentRiskEvidenceRequest);
            });

            if (originalAssessmentState === ServiceAssessmentState.NOT_STARTED) {
                props.navigator.navigateTo(`/${TPRM}/${THIRD_PARTIES}/${serviceResponse?.vendor_id}/${SERVICES}/${serviceResponse?.id}/${DASHBOARDS}`);
            } else {
                await getServiceDetails(hasNewDocumentation);
                setSuccessMessage(existingIRQ ? 'Inherent risk questionnaire updated.' : 'Inherent risk questionnaire created.');
                setExistingIRQ(true);

                // Reset "pending" files (files to upload and files to delete). Uploaded files (`existingFiles`) have already been updated with the data re-fetched by `getServiceDetails`.
                clearFiles();
                setExistingFilesToDelete([]);
            }
        } catch (error) {
            setFailureMessage(error.message);
            setSuccessMessage(undefined);
        } finally {
            setIsCreatingIRQ(false);
        }
    };

    const riskRatingRecommendation = (): string | undefined => {
        if (dataClassification && customerDetailsAnnualVolume && dataStorageLocation) {
            const calculatedRisk = calculateRiskScore(dataClassification, customerDetailsAnnualVolume, dataStorageLocation);
            return `Recommendation: ${RiskRatingAsString(calculatedRisk)}`;
        }
    };

    /**
     * Called after a success response for a "delete/clear IRQ" request has been received.
     * Clears all form fields, as all form data will have been deleted from the backend.
     */
    const irqCleared = (): void => {
        setCustomerDetailsAnnualVolume(undefined);
        setDataClassification(undefined);
        setDataStorageLocation(undefined);
        setIrqText('');
        setSelectedRiskRating(null);
        setSuccessMessage(undefined);
        setFailureMessage(undefined);
        getServiceDetails(); // Note that this will end up calling `setExistingIRQ(false)`, which is why we don't see that line here.
        clearFiles();
        setExistingFiles([]);
    };

    const displayModal = (modal: Modals): void => {
        setDisplayedModal(modal);
    };

    const hideModal = (): void => {
        setDisplayedModal(Modals.None);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (selectedRiskRating !== serviceResponse!.inherent_risk_score || serviceResponse?.assessment_state === ServiceAssessmentState.NOT_STARTED) {
            displayModal(Modals.ConfirmSubmitIrqModal);
        } else {
            setIRQ([]);
        }
    };

    const handleChangeIrqText = (event: React.FormEvent<HTMLInputElement>): void => {
        setIrqText(event.currentTarget.value);
    };

    const handleChangeSelectedRiskRating = (value: ChangeEventType, formFieldId: string): void => {
        setSelectedRiskRating(value as RiskRating);
    };

    const handleChangeDataClassification = (event: React.ChangeEvent<HTMLInputElement>, formFieldId: string): void => {
        setDataClassification(event.target.value as DataClassification);
    };

    const handleChangeCustomerDetailsAnnualVolume = (event: React.ChangeEvent<HTMLInputElement>, formFieldId: string): void => {
        setCustomerDetailsAnnualVolume(event.target.value as CustomerDetailsAnnualVolume);
    };

    const handleChangeDataStorageLocation = (event: React.ChangeEvent<HTMLInputElement>, formFieldId: string): void => {
        setDataStorageLocation(event.target.value as DataStorageLocation);
    };

    if (tprmAccessDenied) {
        return (
            <PageBackground color="white">
                <PageContent>
                    <div className={styles.zeroStateContainer}>
                        <Text>{UNAUTHORIZED_MESSAGE}</Text>
                    </div>
                </PageContent>
            </PageBackground>
        );
    }

    if (serviceResponse && thirdPartyServiceTitle && existingIRQ !== undefined && existingFiles !== undefined && questionCount) {
        const isArchiving = serviceResponse.assessment_state === ServiceAssessmentState.ARCHIVING;
        const clearIRQModalProps: ClearIRQModalProps = {
            hideModal: hideModal,
            serviceID: params.service_id,
            thirdPartyId: params.third_party_id,
            tprmApi: props.tprmApi,
            irqCleared: irqCleared,
        };

        const onConfirmBeginRiskWorkflow = (thirdPartyContacts: ThirdPartyContact[]) => {
            displayModal(Modals.None);
            setIRQ(thirdPartyContacts);
        };

        const confirmSubmitIrqModalProps: ConfirmSubmitIrqModalProps = {
            inherentRiskRating: selectedRiskRating!,
            hideModal: hideModal,
            hasExistingAssessment: serviceResponse.assessment_state === ServiceAssessmentState.IN_PROGRESS && serviceResponse.common_assessment_parent === undefined,
            service: serviceResponse,
            onConfirm: onConfirmBeginRiskWorkflow,
            hasValidDDQConfiguration: selectedRiskRating !== RiskRating.INACTIVE && questionCount[selectedRiskRating!] > 0 ? true : false,
        };

        const fileDragAndDropProps: FileDragAndDropProps = {
            labelText: 'Files',
            tooltip: <FormFieldTooltip text={TPRM_IRQ_FILES} />,
            inputId: 'inherentRiskQuestionnaireDocument',
            onAddFiles: onAddFiles,
            onRemoveFile: onRemoveFile,
            files: files,
            disabled: isCreatingIRQ || isArchiving,
        };

        /**
         * Called when the user clicks the "delete" icon for a file that was uploaded to the backend and to S3 in the past.
         */
        const deleteExistingFile = (file: UploadedFile) => {
            const existingFilesCopy: UploadedFile[] = [...existingFiles];
            existingFilesCopy.splice(existingFiles.indexOf(file), 1);
            setExistingFiles(existingFilesCopy);
            setExistingFilesToDelete([...existingFilesToDelete, file.file_id]);
        };

        return (
            <>
                {successMessage && <TextToast variant="success" clearToast={() => setSuccessMessage(undefined)} text={successMessage} />}
                {failureMessage && <TextToast variant="failure" clearToast={() => setFailureMessage(undefined)} text={failureMessage} />}
                {displayedModal === Modals.ClearIRQModal && <ClearIRQModal {...clearIRQModalProps} />}
                {displayedModal === Modals.ConfirmSubmitIrqModal && <ConfirmSubmitIrqModal {...confirmSubmitIrqModalProps} />}
                <Page
                    headerBreadcrumb={
                        <Breadcrumb textColor="blue">
                            <BreadcrumbLink link={`/${TPRM}/${SERVICES}`}>Third-Party Risk Management</BreadcrumbLink>
                            <BreadcrumbLink link={`/${TPRM}/${THIRD_PARTIES}/${serviceResponse.vendor_id}/${SERVICES}/${serviceResponse.id}/${DASHBOARDS}`}>{thirdPartyServiceTitle}</BreadcrumbLink>
                            <BreadcrumbText>Inherent Risk Questionnaire</BreadcrumbText>
                        </Breadcrumb>
                    }
                    headerTitle={'Inherent Risk Questionnaire'}
                    headerDescription="This form is used to select an inherent risk rating for the service. The inherent risk rating drives prioritization of controls to reduce organizational risk."
                    body={[
                        {
                            content: (
                                <Form noValidate onSubmit={handleSubmit}>
                                    {isArchiving && <Alert variant="warning">This questionnaire is being archived and changes cannot be made.</Alert>}
                                    {originalAssessmentState === ServiceAssessmentState.NOT_STARTED && <Alert variant="warning">The Risk Workflow process has not been started. To begin the process, complete the Inherent Risk Questionnaire.</Alert>}
                                    <Text noStyles>1. What is the classification of the most sensitive data the third party will access for the service?</Text>
                                    <RadioButtonGroup disabled={isArchiving} formFieldId="dataClassification" handleChange={handleChangeDataClassification} options={Question1Options} selectedOption={dataClassification} />
                                    <Text noStyles>2. What is the annual volume of the most sensitive data the third party will access or process for the service?</Text>
                                    <RadioButtonGroup disabled={isArchiving} formFieldId="customerDetailsAnnualVolume" handleChange={handleChangeCustomerDetailsAnnualVolume} options={Question2Options} selectedOption={customerDetailsAnnualVolume} />
                                    <Text noStyles>3. Where is the most sensitive data stored that the third party will access for the service?</Text>
                                    <RadioButtonGroup disabled={isArchiving} formFieldId="dataStorageLocation" handleChange={handleChangeDataStorageLocation} options={Question3Options} selectedOption={dataStorageLocation} />
                                    <FormFieldTextArea formFieldId="irqText" disabled={serviceResponse.assessment_state === ServiceAssessmentState.ARCHIVING} formFieldLabel="Additional Information" handleChange={handleChangeIrqText} value={irqText} tooltip={TPRM_IRQ_ADDITIONAL_INFORMATION} />
                                    <FileDragAndDrop {...fileDragAndDropProps} />
                                    {existingFiles.length > 0 && (
                                        <>
                                            <VisualLabel>Existing Files</VisualLabel>
                                            <Table>
                                                <TableBody>
                                                    {[...existingFiles]
                                                        .sort((fileA, fileB) => fileA.filename.localeCompare(fileB.filename))
                                                        .map((file) => (
                                                            <TableRow key={file.file_id}>
                                                                <TableCellDefaultText>
                                                                    <UploadedFileAndState unclickable documentApi={props.documentApi} file={file} />
                                                                </TableCellDefaultText>
                                                                <TableOverflowCell>
                                                                    <div className={styles.overflowContainer}>
                                                                        {file.file_state === FileState.PASSED && (
                                                                            <OverflowMenu
                                                                                overflowItems={[
                                                                                    {
                                                                                        text: 'Download file',
                                                                                        onClickAction: () => downloadDocument(props.documentApi, file),
                                                                                        icon: faDownload,
                                                                                    },
                                                                                    {
                                                                                        text: 'Delete file',
                                                                                        onClickAction: () => deleteExistingFile(file),
                                                                                        icon: faTrash,
                                                                                    },
                                                                                ]}
                                                                                accessibilityTitle={`${file.filename} Menu`}
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </TableOverflowCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </>
                                    )}
                                    <FormFieldSelect disabled={isArchiving} formFieldId="selectedRiskRating" formFieldLabel="Inherent Risk Rating" options={RiskRatingSelectOptions} selectedOption={selectedRiskRating} handleChange={handleChangeSelectedRiskRating} isRequiredField badge={riskRatingRecommendation()} />
                                    <div className={styles.buttonContainer}>
                                        <Button variant="submit" disabled={isCreatingIRQ || isArchiving} isLoading={isCreatingIRQ === true} loadingText={'Saving...'}>
                                            {originalAssessmentState === ServiceAssessmentState.NOT_STARTED ? 'BEGIN RISK WORKFLOW' : 'SAVE'}
                                        </Button>
                                        {existingIRQ && (
                                            <Button variant="danger" disabled={isArchiving} onClick={() => displayModal(Modals.ClearIRQModal)} fontAwesomeImage={faTrash} loadingText={'Clearing...'} isLoading={false}>
                                                CLEAR QUESTIONNAIRE
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            ),
                        },
                    ]}
                />
            </>
        );
    }

    return <Placeholder />;
};
